export const ProviderAccountPurpose = {
  Custody: "custody",
  Deductions: "deductions",
  FasterPayReserve: "faster_pay_reserve",
  PayCard: "pay_card",
  Reserve: "reserve",
  Tax: "tax",
} as const;

export type ProviderAccountPurpose =
  (typeof ProviderAccountPurpose)[keyof typeof ProviderAccountPurpose];
