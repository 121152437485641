import {
  GetRuleSetRequest,
  GetRuleSetResponse,
  RULE_SET_ROUTES,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const getRuleSet = (axios: IAxiosInstance) => {
  return async (data: GetRuleSetRequest) => {
    const response = await axios.noRetry.get<IApiResponse<GetRuleSetResponse>>(
      `${RULE_SET_ROUTES.GET_RULE_SET.replace(
        ":companyID",
        data.companyID
      ).replace(":ruleSetID", data.ruleSetID)}`,
      { params: { companyID: data.companyID } }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
