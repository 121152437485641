import { schemes } from "@zeal/common";
import { z } from "zod";

export const postKickBackWorkerI9RequestSchema = z.object({
  companyID: schemes.companyID(),
  workerID: schemes.mongoObjectId(),
  testMode: z.boolean(),
});

export type TPostKickBackWorkerI9Request = z.infer<
  typeof postKickBackWorkerI9RequestSchema
>;
