import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetWorkerWalletTransactionsRequestSchema,
  TGetWorkerWalletTransactionsResponseSchema,
} from "./getWorkerWalletTransactions.schema";

export const getWorkerWalletTransactions = (axios: IAxiosInstance) => {
  return async (data: TGetWorkerWalletTransactionsRequestSchema) => {
    const { wallet_id, worker_id, worker_type, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetWorkerWalletTransactionsResponseSchema>
    >(`/api/${worker_type}/${worker_id}/wallets/${wallet_id}/transactions`, {
      params: rest,
    });

    return response.data;
  };
};
