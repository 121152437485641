import {
  payrollRunSchema,
  PayrollRunStatus,
  PayrollRunType,
  zSchema,
} from "@zeal/common";

export const getPayrollRunsRequestSchema = zSchema.object({
  companyID: zSchema.string(),
  status: zSchema.nativeEnum(PayrollRunStatus),
  type: zSchema.nativeEnum(PayrollRunType).optional(),
});

export type TGetPayrollRunsRequestSchema = zSchema.infer<
  typeof getPayrollRunsRequestSchema
>;

export const getPayrollRunsResponseSchema = zSchema.array(
  payrollRunSchema.extend({
    isLate: zSchema.boolean(),
  })
);

export type TGetPayrollRunsResponseSchema = zSchema.infer<
  typeof getPayrollRunsResponseSchema
>;
