import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export function useQueryCache<T, A>(useQueryOptions: UseQueryOptions<T, A>) {
  return useQuery({
    ...useQueryOptions,
    refetchOnWindowFocus: useQueryOptions.refetchOnWindowFocus ?? false, // https://react-query-v3.tanstack.com/guides/window-focus-refetching
    staleTime: useQueryOptions.staleTime ?? 60 * 60 * 60 * 5, // 5 min stale times
    retryOnMount: useQueryOptions.retryOnMount ?? false,
  });
}
