import { schemes, zSchema } from "../../schema-validation";
import { WalletStatus } from "../WalletStatus";

export const laForgeWalletSchema = zSchema.object({
  available_balance: zSchema.number(),
  companyID: schemes.companyID(),
  contractorID: schemes.mongoObjectId().optional(),
  current_balance: zSchema.number(),
  employeeID: schemes.mongoObjectId().optional(),
  status: zSchema.nativeEnum(WalletStatus),
  walletID: schemes.uuid(),
});
