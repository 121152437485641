import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateCustomerAccountOnboardingWebhook = (
  axios: IAxiosInstance
) => {
  return async ({
    customerAccountOnboardingWebhook,
    userType,
    isTestWebhookType,
    usePrimary,
  }: ISchema["#/definitions/IUpdateCustomerAccountOnboardingWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateCustomerAccountOnboardingWebhookResponse"]
    >(
      `/api/updateCustomerAccountOnboardingWebhook${getUsePrimaryUrlParams(
        usePrimary
      )}`,
      {
        customer_account_onboarding_webhook: customerAccountOnboardingWebhook,
        user_type: userType,
        is_test_webhook_type: isTestWebhookType,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
