import type { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import { createWorkLocation } from "./createWorkLocation/createWorkLocation";
import { createWorkLocationByAddress } from "./createWorkLocationByAddress/createWorkLocationByAddress";

export function WorkLocationApi(instance: IAxiosInstance) {
  return {
    Create: createWorkLocation(instance),
    CreateByAddress: createWorkLocationByAddress(instance),
  };
}
