export enum UserAccountType {
  CompanyAdmin = "admin",
  CompanyGuest = "guest",
  CompanyContractor = "contractor",
  CompanyContractorIntl = "international_contractor",
  CompanyWorker = "worker",
  Internal = "puzzl_internal",
  InternalGuest = "puzzl_internal_guest",
  Whitelabel = "white_label",
  WhitelabelAdmin = "wladmin",
  Company = "Company",
}

// subset of UserAccountType for Worker types
export type UserAccountWorkerType = Extract<
  UserAccountType,
  | UserAccountType.CompanyWorker
  | UserAccountType.CompanyContractor
  | UserAccountType.CompanyContractorIntl
>;

// subset of UserAccountType for Member types
export type UserAccountMemberType = Exclude<
  UserAccountType,
  UserAccountWorkerType
>;

export const UserAccountTypeArray = [
  UserAccountType.CompanyAdmin,
  UserAccountType.CompanyGuest,
  UserAccountType.CompanyContractor,
  UserAccountType.CompanyContractorIntl,
  UserAccountType.CompanyWorker,
  UserAccountType.Internal,
  UserAccountType.InternalGuest,
  UserAccountType.Whitelabel,
  UserAccountType.WhitelabelAdmin,
  UserAccountType.Company,
] as const;

export type AccountLoginType = (typeof AccountLoginTypeArray)[number];
export const AccountLoginTypeArray = [
  UserAccountType.CompanyAdmin,
  UserAccountType.CompanyGuest,
  UserAccountType.CompanyContractor,
  UserAccountType.CompanyContractorIntl,
  UserAccountType.CompanyWorker,
  UserAccountType.Internal,
  UserAccountType.InternalGuest,
  UserAccountType.Whitelabel,
  UserAccountType.WhitelabelAdmin,
] as const;

export const UserAccountWorkerTypeArray = [
  UserAccountType.CompanyContractor,
  UserAccountType.CompanyContractorIntl,
  UserAccountType.CompanyWorker,
] as const;

export const UserAccountMemberTypeArray = [
  UserAccountType.Internal,
  UserAccountType.InternalGuest,
  UserAccountType.CompanyAdmin,
  UserAccountType.CompanyGuest,
  UserAccountType.Whitelabel,
  UserAccountType.WhitelabelAdmin,
] as const;

export const userAccountTypes: Set<UserAccountType> = new Set(
  UserAccountTypeArray
);

export enum UserTypeV2Token {
  Partner = "WhiteLabel",
  PartnerAdmin = "WhiteLabelAdmin",
  Company = "User",
  Guest = "guest",
  CompanyAdmin = "admin",
  Contractor = "contractor",
  Employee = "Worker",
  internationalContractor = "internationalContractor",
  Internal = "Internal",
  InternalGuest = "InternalGuest",
}

// This needs to die so bad.
// Idk why there is this split but we need reconcile it

export const parseUserAccountTypeFromUserTokenType = (
  tokenType: UserTypeV2Token
): UserAccountType => {
  switch (tokenType) {
    case UserTypeV2Token.Partner:
      return UserAccountType.Whitelabel;
    case UserTypeV2Token.PartnerAdmin:
      return UserAccountType.WhitelabelAdmin;
    case UserTypeV2Token.Company:
      return UserAccountType.Company;
    case UserTypeV2Token.Guest:
      return UserAccountType.CompanyGuest;
    case UserTypeV2Token.CompanyAdmin:
      return UserAccountType.CompanyAdmin;
    case UserTypeV2Token.Contractor:
      return UserAccountType.CompanyContractor;
    case UserTypeV2Token.Employee:
      return UserAccountType.CompanyWorker;
    case UserTypeV2Token.internationalContractor:
      return UserAccountType.CompanyContractorIntl;
    case UserTypeV2Token.Internal:
      return UserAccountType.Internal;
    case UserTypeV2Token.InternalGuest:
      return UserAccountType.InternalGuest;
    default:
      return "UnknownUserAccountType" as UserAccountType; // temp
  }
};

// do opposite
export const parseUserTokenTypeFromUserAccountType = (
  userAccountType: UserAccountType
): UserTypeV2Token => {
  switch (userAccountType) {
    case UserAccountType.Whitelabel:
      return UserTypeV2Token.Partner;
    case UserAccountType.WhitelabelAdmin:
      return UserTypeV2Token.PartnerAdmin;
    case UserAccountType.Company:
      return UserTypeV2Token.Company;
    case UserAccountType.CompanyGuest:
      return UserTypeV2Token.Guest;
    case UserAccountType.CompanyAdmin:
      return UserTypeV2Token.CompanyAdmin;
    case UserAccountType.CompanyContractor:
      return UserTypeV2Token.Contractor;
    case UserAccountType.CompanyWorker:
      return UserTypeV2Token.Employee;
    case UserAccountType.CompanyContractorIntl:
      return UserTypeV2Token.internationalContractor;
    case UserAccountType.Internal:
      return UserTypeV2Token.Internal;
    case UserAccountType.InternalGuest:
      return UserTypeV2Token.InternalGuest;
    default:
      return "UnknownUserTokenType" as UserTypeV2Token; // temp
  }
};

// These types are messed up but they were inferred from the CurrentPrimaryUser type in morn
type TBadUserType =
  | UserAccountType
  | "contractor"
  | "international_contractor"
  | "company"
  | "puzzl_internal"
  | "puzzl_internal_guest"
  | "sole_co"
  | "worker"
  | "white_label"
  | "wladmin"
  | "guest"
  | "admin";

const guestUserTypes: TBadUserType[] = [
  UserAccountType.InternalGuest,
  UserAccountType.CompanyGuest,
];

export const getIsUserTypeGuest = (userType?: TBadUserType): boolean =>
  !!userType && guestUserTypes.includes(userType);

export const getIsUserTypeNonGuest = (userType?: TBadUserType): boolean =>
  !!userType && !guestUserTypes.includes(userType);
