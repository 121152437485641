import { z } from "zod";
import { EApplicableEarningTypes, IMinimumWageViolationEvent } from "../types";

const applicableEarningTypeSchema = z.nativeEnum(EApplicableEarningTypes);

export const statefulEarningSchema = z.object({
  wage: z.number(),
  type: applicableEarningTypeSchema,
  shiftID: z.string(),
  workLocationID: z.string(),
  time: z.string(),
});

export const statelessEarningSchema = z.object({
  wage: z.number(),
  type: applicableEarningTypeSchema,
  shiftID: z.string(),
  features: z.array(z.string()),
  time: z.string(),
});

export const getViolationsSchema = z.object({
  companyID: z.string(),
  defaultComplianceParameters: z.object({
    HEADCOUNT: z.string(),
  }), // TODO: this should be defined elsewhere
  earnings: z.array(statefulEarningSchema.or(statelessEarningSchema)),
  persistEvents: z.boolean().optional(),
  submissionTime: z.string().optional(),
});

export type TGetViolationsRequestBody = z.infer<typeof getViolationsSchema>;

export type TGetViolationsResponseData = Array<IMinimumWageViolationEvent>;
