import { laForgeWalletSchema, schemes, zSchema } from "@zeal/common";

export const getWalletsRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  worker_id: schemes.uuid(),
  worker_type: zSchema.enum(["contractors", "employees"]),
});

export type TGetWalletsRequestSchema = zSchema.infer<
  typeof getWalletsRequestSchema
>;

export type TGetWalletsResponseSchema = zSchema.infer<
  typeof laForgeWalletSchema
>[];
