import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetWalletsRequestSchema,
  TGetWalletsResponseSchema,
} from "./getWallets.schema";

export const getWallets = (axios: IAxiosInstance) => {
  return async (params: TGetWalletsRequestSchema) => {
    const { odo_user_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetWalletsResponseSchema>
    >(`/api/employees/${odo_user_id}/wallets`, { params: rest });

    return response.data;
  };
};
