export const EPaymentCreationType = {
  COMPANY_TAX_PENALTY: "company_tax_penalty",
  FASTER_PAY: "faster_pay",
  FUND_EMPLOYER_CUSTODY: "fund_employer_custody",
  FUND_EMPLOYER_RESERVE: "fund_employer_reserve",
  FUND_TAX_NODE: "fund_tax_node",
  ONE_DAY: "one_day",
  ONE_DAY_DEBIT: "one_day_debit",
  ONE_DAY_PAYCARD_TRANSFER: "one_day_paycard_transfer",
  ONE_DAY_TAX_TRANSFER: "one_day_tax_transfer",
  PAY_EMPLOYEE: "pay_employee",
  PAY_STATE_FROM_CUSTODY: "pay_state_from_custody",
  PAY_STATE: "pay_state",
  PAYCARD_DIRECT_PAY: "paycard_direct_pay",
  PAYCARD_DISBURSEMENT: "paycard_disbursement",
  RESERVE_PAYCARD_DISBURSEMENT: "reserve_paycard_disbursement",
  PREFUNDED_BATCH_DISBURSEMENT: "prefunded_batch_disbursement",
  PREFUNDED_EMPLOYEE_DISBURSEMENT: "prefunded_employee_disbursement",
  PUSH_DEDUCTIONS: "push_deductions",
  PUSH_TO_CARD: "push_to_card",
  PUSH_TWO_PERCENT: "push_two_percent",
  REFUND_CUSTOMER_ACCOUNT_PAYROLL: "refund_customer_account_payroll",
  REFUND_PAYROLL: "refund_payroll",
  REFUND_RESERVE_PAYROLL: "refund_reserve_payroll",
  REFUND_TAX: "refund_tax",
  SAME_DAY_FASTER_RESERVE_TOP_UP: "same_day_faster_reserve_top_up",
  SAME_DAY_RESERVE_TOP_UP: "same_day_reserve_top_up",
  TAX_PENALTY: "tax_penalty",
  TWO_DAY_DEDUCTIONS_TRANSFER: "two_day_deductions_transfer",
  TWO_DAY_PAYCARD_TRANSFER: "two_day_paycard_transfer",
  TWO_DAY_TAX_TRANSFER: "two_day_tax_transfer",
  WALLET: "wallet",
  TWO_DAY_TWO_PERCENT_SHAREHOLDER_REIMBURSEMENT_TRANSFER:
    "two_day_two_percent_shareholder_healthcare_reimbursement_transfer",
} as const;

export type EPaymentCreationType =
  (typeof EPaymentCreationType)[keyof typeof EPaymentCreationType];
