import { schemes, zSchema } from "@zeal/common";

export const createPushToCardBusinessRequestSchema = zSchema.object({
  address: zSchema.string(),
  city: zSchema.string(),
  company_id: schemes.companyID(),
  business_name: zSchema.string(),
  business_type: zSchema.string(),
  email: zSchema.string().email(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  odo_user_id: schemes.mongoOrUuid(),
  postal_code: schemes.zipCode(),
  state: schemes.jurisdiction(),
});

export type TCreatePushToCardBusinessRequestSchema = zSchema.infer<
  typeof createPushToCardBusinessRequestSchema
>;

export const createPushToCardBusinessResponseSchema = zSchema.object({
  odo_user_id: zSchema.string(),
});

export type TCreatePushToCardBusinessResponseSchema = zSchema.infer<
  typeof createPushToCardBusinessResponseSchema
>;
