import { schemes, zSchema } from "../../schema-validation";
import { WalletTransactionType } from "../WalletTransactionType";

export const walletTransactionSchema = zSchema.object({
  amount: zSchema.number(),
  charge_id: schemes.uuid().optional(),
  date: zSchema.date(),
  description: zSchema.string(),
  last_four: zSchema.string(),
  type: zSchema.nativeEnum(WalletTransactionType),
  wallet_id: schemes.uuid(),
  wallet_transaction_id: schemes.uuid(),
});
