import { DeleteAttributeRequest, CUSTOM_ATTRIBUTE_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const deleteAttribute = (axios: IAxiosInstance) => {
  return async (data: DeleteAttributeRequest) => {
    const response = await axios.noRetry.delete<IApiResponse<any>>(
      `${CUSTOM_ATTRIBUTE_ROUTES.DELETE_ATTRIBUTE.replace(
        ":attributeID",
        data.attributeID
      )}`,
      { params: { companyID: data.companyID, type: data.type } }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
