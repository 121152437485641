import { type Dayjs } from "dayjs";
import dayjs from "./dayjs";
import { TransactionSpeedConfig } from "../transaction-speeds/TransactionSpeedConfig";
import { ETxnSpeed } from "../transaction-speeds/ETxnSpeed";

export const getCutOffTimeWithSpeed = (
  date: string | Dayjs,
  speed: ETxnSpeed
) => {
  const speedConfig = TransactionSpeedConfig[speed];
  const configDate = dayjs(date);

  if (!configDate.isValid()) return undefined;

  return configDate
    .tz("America/Los_Angeles")
    .startOf("day")
    .subtractBusinessDays(speedConfig.daysBeforeCheckDate)
    .set("hour", speedConfig.cutoffHour)
    .set("minute", speedConfig.cutoffMinute)
    .utc()
    .format();
};
