import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetWalletsRequestSchema,
  TGetWalletsResponseSchema,
} from "./getWallets.schema";

export const getWallets = (axios: IAxiosInstance) => {
  return async (data: TGetWalletsRequestSchema) => {
    const { worker_id, worker_type, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetWalletsResponseSchema>
    >(`/api/${worker_type}/${worker_id}/wallets`, {
      params: rest,
    });

    return response.data;
  };
};
