import type { ZealApiResponse } from "@zeal/common";
import type { ISetCompanyOnboardStatusRequestBody } from "./ISetCompanyOnboardStatusRequestBody.jsonschema";
import type { ISetCompanyOnboardStatusResponseBody } from "./ISetCompanyOnboardStatusResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies/onboardCompany";

type SetCompanyOnboardStatusResponse =
  ZealApiResponse<ISetCompanyOnboardStatusResponseBody>;

export const setCompanyOnboardStatus =
  (axios: IAxiosInstance) =>
  async (body: ISetCompanyOnboardStatusRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<SetCompanyOnboardStatusResponse>(PATH, body);
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
