import type { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import { createBulkEmployees } from "./createBulkEmployees/createBulkEmployees";
import { genEmployeeOnboardingLink } from "./genEmployeeOnboardingLink/genEmployeeOnboardingLink";
import { getEmployeeInfo } from "./getEmployeeInfo/getEmployeeInfo";
import { setEmployeeOnboardStatus } from "./setEmployeeOnboardStatus/setEmployeeOnboardStatus";

export function EmployeeApi(instance: IAxiosInstance) {
  return {
    CreateBulk: createBulkEmployees(instance),
    GenOnboardingLink: genEmployeeOnboardingLink(instance),
    GetInfo: getEmployeeInfo(instance),
    SetOnboardToTrue: setEmployeeOnboardStatus(instance),
  };
}
