import type { ZealApiResponse } from "@zeal/common";
import type { IGetEmployeeInfoRequestParams } from "./IGetEmployeeInfoRequestParams.jsonschema";
import type { GetEmployeeInfoResponseBody } from "./GetEmployeeInfoResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/employees";

type GetEmployeeInfoResponse = ZealApiResponse<GetEmployeeInfoResponseBody>;

export const getEmployeeInfo =
  (axios: IAxiosInstance) => async (params: IGetEmployeeInfoRequestParams) => {
    try {
      const response = await axios.noRetry.get<GetEmployeeInfoResponse>(PATH, {
        params,
      });
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
