import { EDisbursementMethod } from "../workers-shared";
import { ETxnSpeed } from "./ETxnSpeed";

export const DisbursementMethodSpeedConfig = {
  [EDisbursementMethod.AVAILABLE_PAY]: {
    cutoffHour: 23,
    cutoffMinute: 59,
    daysBeforeCheckDate: 0,
  },
  [EDisbursementMethod.DIRECT_DEPOSIT]: {
    cutoffHour: 14,
    cutoffMinute: 30,
    daysBeforeCheckDate: 1,
  },
  [EDisbursementMethod.DIRECT_DEPOSIT_SAME_DAY]: {
    cutoffHour: 11,
    cutoffMinute: 30,
    daysBeforeCheckDate: 0,
  },
  [EDisbursementMethod.DOWNLOAD_CHECK]: {
    cutoffHour: 12,
    cutoffMinute: 30,
    daysBeforeCheckDate: 0,
  },
  [EDisbursementMethod.PAYCARD]: {
    cutoffHour: 23,
    cutoffMinute: 59,
    daysBeforeCheckDate: 0,
  },
  [EDisbursementMethod.PHYSICAL_CHECK]: {
    cutoffHour: 11,
    cutoffMinute: 30,
    daysBeforeCheckDate: 0,
  },
  [EDisbursementMethod.PREPAID]: {
    cutoffHour: 23,
    cutoffMinute: 59,
    daysBeforeCheckDate: 0,
  },
};

export const TransactionSpeedConfig = {
  [ETxnSpeed.FOUR_DAY]: {
    cutoffHour: 14,
    cutoffMinute: 0,
    daysBeforeCheckDate: 4,
  },
  [ETxnSpeed.ONE_DAY]: {
    cutoffHour: 12,
    cutoffMinute: 30,
    daysBeforeCheckDate: 1,
  },
  [ETxnSpeed.ONE_DAY_NO_RESERVE]: {
    cutoffHour: 11,
    cutoffMinute: 30,
    daysBeforeCheckDate: 1,
  },
  [ETxnSpeed.TWO_DAY]: {
    cutoffHour: 14,
    cutoffMinute: 0,
    daysBeforeCheckDate: 2,
  },
  [ETxnSpeed.ZERO_DAY]: {
    cutoffHour: 11,
    cutoffMinute: 30,
    daysBeforeCheckDate: 0,
  },
  [ETxnSpeed.ZERO_DAY_NO_RESERVE]: {
    cutoffHour: 9,
    cutoffMinute: 30,
    daysBeforeCheckDate: 0,
  },
} as const;
