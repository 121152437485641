import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreatePushToCardBusinessRequestSchema,
  TCreatePushToCardBusinessResponseSchema,
} from "./createPushToCardBusiness.schema";

export const createPushToCardBusiness = (axios: IAxiosInstance) => {
  return async (body: TCreatePushToCardBusinessRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreatePushToCardBusinessResponseSchema>
    >(`/api/push-to-card/business`, body);

    return response.data;
  };
};
