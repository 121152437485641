import dayjs from "dayjs";

export const buildDate = ({
  day = 1,
  month = 1,
  year = new Date().getFullYear(),
}) =>
  dayjs(
    `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`,
    "YYYY-MM-DD"
  );
