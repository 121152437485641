import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateWalletRequestSchema,
  TCreateWalletResponseSchema,
} from "./createWallet.schema";

export const createWallet = (axios: IAxiosInstance) => {
  return async (params: TCreateWalletRequestSchema) => {
    const { odo_user_id } = params;

    const response = await axios.noRetry.post<
      IApiResponse<TCreateWalletResponseSchema>
    >(`/api/employees/${odo_user_id}/wallets`);

    return response.data;
  };
};
