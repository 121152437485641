export const EContractorPaymentStatus = {
  pending: "pending",
  "pre-processed": "pre-processed",
  processed: "processed",
  voided: "voided",
  failed: "failed",
} as const;

export type EContractorPaymentStatus =
  (typeof EContractorPaymentStatus)[keyof typeof EContractorPaymentStatus];
