import type { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import { createBulkContractors } from "./createBulkContractors/createBulkContractors";
import { genContractorOnboardingLink } from "./genContractorOnboardingLink/genContractorOnboardingLink";
import { getContractorInfo } from "./getContractorInfo/getContractorInfo";
import { setContractorOnboardStatus } from "./setContractorOnboardStatus/setContractorOnboardStatus";

export function ContractorApi(instance: IAxiosInstance) {
  return {
    CreateBulk: createBulkContractors(instance),
    GenOnboardingLink: genContractorOnboardingLink(instance),
    GetInfo: getContractorInfo(instance),
    SetOnboardedStatusToTrue: setContractorOnboardStatus(instance),
  };
}
