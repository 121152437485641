import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetWorkerWalletsRequestSchema,
  TGetWorkerWalletsResponseSchema,
} from "./getWorkerWallets.schema";

export const getWorkerWallets = (axios: IAxiosInstance) => {
  return async (data: TGetWorkerWalletsRequestSchema) => {
    const { worker_id, worker_type, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetWorkerWalletsResponseSchema>
    >(`/api/${worker_type}/${worker_id}/wallets`, { params: rest });

    return response.data;
  };
};
