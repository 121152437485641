import dayjs, { ConfigType } from "dayjs";

type DateInput = ConfigType;

type DateFormat = typeof US_DATE_FORMAT | "MMMM D, YYYY" | "MMM D, YYYY";

const US_DATE_FORMAT = "MM/DD/YYYY";
const US_DATE_TIME_FORMAT = `${US_DATE_FORMAT} h:mm a`;
const ISO_DATE_FORMAT = "YYYY-MM-DD";

const getDate = (date: DateInput) => (dayjs.isDayjs(date) ? date : dayjs(date));

export const getFormattedDate = (date?: DateInput, format?: DateFormat) => {
  if (!date) return null;

  const d = getDate(date);

  return d.utc().format(format ?? "MMMM D, YYYY");
};

export const getFormattedDateWithTime = (date?: DateInput) => {
  if (!date) return null;

  const d = getDate(date);

  return d.utc().local().format("h:mma on MMMM D, YYYY");
};

export const getISOFormattedDate = (date?: DateInput, utc = true) => {
  if (!date) return null;

  const d = getDate(date);

  if (utc) {
    return d.utc().format(ISO_DATE_FORMAT);
  }

  return d.format(ISO_DATE_FORMAT);
};

export const getUSFormattedDate = (
  date?: DateInput,
  utc = true
): string | null => {
  if (!date) return null;

  const d = getDate(date);

  if (utc) {
    return d.utc().format(US_DATE_FORMAT);
  }

  return d.format(US_DATE_FORMAT);
};

export const isValidDate = (date?: string) => dayjs(date).isValid();

export const getUSDateAndTime = (date: DateInput) => {
  if (!date) return null;

  return getDate(date).format(US_DATE_TIME_FORMAT);
};

export const currentDate = new Date();
export const currentEffectiveDate = new Date().toISOString().split("T")[0];
export const today = dayjs().startOf("day").format(ISO_DATE_FORMAT);
export const minDob = new Date(
  currentDate.getFullYear() - 115,
  currentDate.getMonth(),
  currentDate.getDate()
);

export const maxDob = new Date(
  currentDate.getFullYear() - 14,
  currentDate.getMonth(),
  currentDate.getDate()
);

export const getISOFormattedDateFromJSDate = (date: Date) =>
  date.toISOString().substring(0, 10);

export const getStartOfCurrentYear = () => {
  const currentYear = currentDate.getFullYear().toString();
  return dayjs(currentYear).utc().format(ISO_DATE_FORMAT);
};

/**
 * Use this to convert dates like "12-31-2023" to ISO-8601 format.
 *
 * Especially useful with dash delimited dates since the dash delimiter violates spec and results in parsing issues.
 *
 * @date string value of the date in mmddyyyy format (or with delimiter like mm-dd-yyyy)
 * @delimiter optional argument to specify if a delimiter is used in the string
 */
export const mmddyyyyToISO8601 = (date: string, delimiter?: string) => {
  const [month, day, year] = !delimiter
    ? [date.substring(0, 2), date.substring(2, 4), date.substring(4, 8)]
    : date.split(delimiter);
  return `${year}-${month}-${day}`;
};
