import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdatePaycardResponseSchema,
  TUpdatePaycardRequestSchema,
} from "./updatePaycard.schema";

export const updatePaycard = (axios: IAxiosInstance) => {
  return async (body: TUpdatePaycardRequestSchema) => {
    const { paycard_id, ...rest } = body;

    const response = await axios.noRetry.patch<
      IApiResponse<TUpdatePaycardResponseSchema>
    >(`/api/paycards/${paycard_id}`, rest);

    return response.data;
  };
};
