import { schemes, walletTransactionSchema, zSchema } from "@zeal/common";

export const getWalletTransactionsRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  wallet_id: schemes.uuid(),
  worker_id: schemes.uuid(),
  worker_type: zSchema.enum(["contractors", "employees"]),
  limit: zSchema.number().optional(),
  start_at: schemes.uuid().optional(),
});

export type TGetWalletTransactionsRequestSchema = zSchema.infer<
  typeof getWalletTransactionsRequestSchema
>;

export type TGetWalletTransactionsResponseSchema = zSchema.infer<
  typeof walletTransactionSchema
>[];
