import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TWorkerPaycardOnboardingWebhookBody,
  TWorkerPaycardOnboardingWebhookRequest,
} from "./testWorkerPaycardOnboardingWebhook.schema";

export const testWorkerPaycardOnboardingWebhook = (axios: IAxiosInstance) => {
  return async ({ testMode, url }: TWorkerPaycardOnboardingWebhookRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<TWorkerPaycardOnboardingWebhookBody>
    >("/api/testWorkerPaycardOnboardingWebhook", {
      url,
      testMode,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
