import { z } from "zod";

import { baseEventInput } from "./baseEvent";

export const notificationEvent = baseEventInput.merge(
  z.object({
    source: z.literal("notifications"),
    payload: z
      .object({
        medium: z.enum(["email", "push_notification"]),
        odoUserId: z.string().optional(),
        templateVariables: z.record(z.any()).optional(),
        type: z.enum(["paycard_token_activation", "paycard_payment"]),
      })
      .passthrough(),
  })
);

export type NotificationEvent = z.infer<typeof notificationEvent>;
