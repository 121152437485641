/**
 * @param accountNumber The bank account number to redact
 * @returns The redacted bank account number, showing only the last 4 digits
 * @example
 * getRedactedBankAccountNumber("1234567890123456") // returns "••••••••••••3456"
 */
export const getRedactedBankAccountNumber = (accountNumber: string) => {
  if (!accountNumber || accountNumber.length <= 4) return "";

  return `${"•".repeat(accountNumber.length - 4)}${accountNumber.slice(-4)}`;
};
