import { z } from "zod";

export const getI9OnboardingLinkRequestSchema = z.object({
  employeeID: z.string(),
  send_to_worker: z.boolean().optional(),
});

export type TGetI9OnboardingLinkRequest = z.infer<
  typeof getI9OnboardingLinkRequestSchema
>;
