import { PayCardStatus, zSchema } from "@zeal/common";

export const getPayCardsRequestSchema = zSchema.object({
  odo_user_id: zSchema.string(),
  status: zSchema.nativeEnum(PayCardStatus).optional(),
});

export type TGetPayCardsRequestSchema = zSchema.infer<
  typeof getPayCardsRequestSchema
>;

export const getPayCardsResponseSchema = zSchema.array(
  zSchema.object({
    available_balance: zSchema.string(),
    current_balance: zSchema.string(),
    external_pay_card_id: zSchema.string(),
    odo_user_id: zSchema.string(),
    pay_card_id: zSchema.string(),
    program_name: zSchema.string(),
    provider_account_id: zSchema.string(),
    status: zSchema.nativeEnum(PayCardStatus),
    pin_is_set: zSchema.boolean(),
  })
);

export type TGetPayCardsResponseSchema = zSchema.infer<
  typeof getPayCardsResponseSchema
>;
