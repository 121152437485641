import { z } from "zod";
import { EDocumentKey, EDocumentList } from "./EDocumentKey";
import { allKeysOptionalI9DocumentZodSchema } from "./zod-schemas";

export type TI9DocumentData = z.infer<
  typeof allKeysOptionalI9DocumentZodSchema
>;

export enum EI9DocumentPhotoSide {
  FRONT = "FRONT",
  BACK = "BACK",
  OTHER = "OTHER",
}

export interface II9DocumentPhotos {
  doc_url?: string;
  doc_base64?: string;
  doc_blob?: Buffer;
  description?: string;
  side?: EI9DocumentPhotoSide;
  file_suffix?: number; // number appended to EDocumentKey to get URL
}

export const veriffIDVDecisionResponseSchema = z.object({
  id: z.string(),
  status: z.enum(["approved", "declined"]),
  reason: z.string().optional(),
  reasonCode: z.number().optional(),
  riskLabels: z.array(z.string()).optional(),
  riskScore: z.number().optional(),
});

export type VeriffIDVDecisionResponse = z.infer<
  typeof veriffIDVDecisionResponseSchema
>;

export interface II9Document {
  document_key: EDocumentKey;
  document_list: EDocumentList;
  document_data?: TI9DocumentData; // Optional when the worker has not yet provided the document details yet
  document_photos?: II9DocumentPhotos[]; // Optional when the worker has not yet provided the document photos yet
  veriff_id?: string; // Required only when the worker goes through Veriff IDV
  veriff_decision?: VeriffIDVDecisionResponse; // Required only when the worker goes through Veriff IDV
}

export interface II9DocumentTypeFormDataProps {
  document_type_a_code?: EDocumentKey;
  document_type_b_code?: EDocumentKey;
  document_type_c_code?: EDocumentKey;
}
