import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateWalletTransactionRequestSchema,
  TCreateWalletTransactionResponseSchema,
} from "./createWalletTransaction.schema";

export const createWalletTransaction = (axios: IAxiosInstance) => {
  return async (data: TCreateWalletTransactionRequestSchema) => {
    const { wallet_id, worker_id, worker_type, ...rest } = data;

    const response = await axios.noRetry.post<
      IApiResponse<TCreateWalletTransactionResponseSchema>
    >(
      `/api/${worker_type}/${worker_id}/wallets/${wallet_id}/transactions`,
      rest
    );

    return response.data;
  };
};
