import { EDisbursementMethod } from "../workers-shared";

export const getCompanyDisbursementMethods = ({
  availablePayEnabled,
  paycardsEnabled,
  reserveEnabled,
}: {
  availablePayEnabled: boolean;
  paycardsEnabled: boolean;
  reserveEnabled: boolean;
}) => [
  {
    value: EDisbursementMethod.DIRECT_DEPOSIT,
    label: "Direct deposit",
  },
  {
    value: EDisbursementMethod.PHYSICAL_CHECK,
    label: "Physical check",
  },
  {
    value: EDisbursementMethod.PREPAID,
    label: "Prepaid",
  },
  {
    value: EDisbursementMethod.DOWNLOAD_CHECK,
    label: "Download check",
  },
  ...(availablePayEnabled
    ? [{ value: EDisbursementMethod.AVAILABLE_PAY, label: "Available pay" }]
    : []),
  ...(paycardsEnabled
    ? [{ value: EDisbursementMethod.PAYCARD, label: "Paycard" }]
    : []),
  ...(reserveEnabled
    ? [
        {
          value: EDisbursementMethod.DIRECT_DEPOSIT_SAME_DAY,
          label: "Direct deposit same day",
        },
      ]
    : []),
];
