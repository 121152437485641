import { EPaycardOnboardingStatus } from "@zeal/common";
import { z } from "zod";

export const workerPaycardOnboardingWebhookSchema = z.object({
  test: z.boolean(),
  companyID: z.string(),
  employeeID: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  status: z.nativeEnum(EPaycardOnboardingStatus),
  business_name: z.string(),
});

export const workerPaycardOnboardingWebhookRequestSchema = z.object({
  url: z.string(),
  testMode: z.boolean(),
});

export type TWorkerPaycardOnboardingWebhookRequest = z.infer<
  typeof workerPaycardOnboardingWebhookRequestSchema
>;
export type TWorkerPaycardOnboardingWebhookBody = z.infer<
  typeof workerPaycardOnboardingWebhookSchema
>;
