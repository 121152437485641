import { schemes, zSchema } from "../schema-validation";

export const paginationSchema = zSchema.object({
  limit: zSchema.number().min(1).max(100).optional(),
  startAt: schemes
    .mongoOrUuid("A valid start_at pagination cursor is required")
    .optional(),
});

export type TPaginationSchema = zSchema.input<typeof paginationSchema>;

export const publicPaginationSchema = zSchema.object({
  limit: paginationSchema.shape.limit,
  start_at: paginationSchema.shape.startAt,
});

export type TPublicPaginationSchema = zSchema.input<
  typeof publicPaginationSchema
>;
