import { IApiResponse } from "clients/AbstractApiClient/IApiResponse";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TGetWorkerAstraCardRequestSchema,
  TGetWorkerAstraCardResponseSchema,
} from "./getWorkerAstraCard.schema";

export const getWorkerAstraCard = (axios: IAxiosInstance) => {
  return async (data: TGetWorkerAstraCardRequestSchema) => {
    const { workerID } = data;
    const response = await axios.noRetry.get<
      IApiResponse<TGetWorkerAstraCardResponseSchema>
    >("/push-to-card/card-info", { params: { workerID } });

    return response.data;
  };
};
