import { EDocumentKey, EI9DocumentPhotoSide } from "@zeal/common";
import { z } from "zod";

export const uploadI9OnboardingIDDocumentRequestSchema = z.object({
  companyID: z.string().optional(),
  workerID: z.string().optional(),
  document: z.any(),
  side: z.nativeEnum(EI9DocumentPhotoSide),
  documentType: z.nativeEnum(EDocumentKey),
});

export type TUploadI9OnboardingIDDocumentRequest = z.infer<
  typeof uploadI9OnboardingIDDocumentRequestSchema
>;
