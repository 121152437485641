import { schemes, zSchema as z } from "../../schema-validation";
import { BankAccountOwnerType } from "../BankAccountOwnerType";

export const bankAccountSchema = z.object({
  _id: schemes.mongoObjectId(),
  account_number: z.string(),
  bank_account_id: schemes.uuid(),
  companyID: schemes.companyID(),
  institution_name: z.string().nullish(),
  is_primary: z.boolean(),
  owner_type: z.nativeEnum(BankAccountOwnerType).optional(),
  payment_destination_id: schemes.uuid().optional(),
  routing_number: z.string(),
  type: z.enum(["checking", "savings"]).optional(),
});

export const employeeBankAccountTaskSchema = bankAccountSchema.partial();

export type BankAccount = z.infer<typeof bankAccountSchema>;
export type EmployeeBankAccountTask = z.infer<
  typeof employeeBankAccountTaskSchema
>;
