import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetFrigadeClientResponseSchema } from "./getFrigadeClient.schema";

export const getFrigadeClient = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<TGetFrigadeClientResponseSchema>(
      "/api/getFrigadeClient"
    );

    return response.data;
  };
};
