import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetCompanyCardProductsResponseSchema } from "./getCompanyCardProducts.schema";

export const getCompanyCardProducts =
  (axios: IAxiosInstance) => async (params: { odo_user_id: string }) => {
    const { odo_user_id } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetCompanyCardProductsResponseSchema>
    >(`/api/companies/${odo_user_id}/card-products`);

    return response.data;
  };
