import { z } from "zod";
import { PaycardType } from "../../pay-cards";
import { schemes } from "../../schema-validation";

export const cardProductSchema = z.object({
  card_product_id: schemes.uuid(),
  card_type: z.nativeEnum(PaycardType),
  external_card_product_id: z.string(),
  odo_user_id: schemes.mongoOrUuid(),
  program_name: z.string(),
});
