import { z } from "zod";

import { EGovernmentPhotoIDType } from "../../accounts/onboarding/types";
import { ZealErrorAlias } from "../../errors/ZealErrors";
import { Jurisdiction } from "../../jurisdictions/Jurisdiction";
import { EKYCStatus, EKYCStatusList } from "../../kyc/EKYCStatus";
import { schemes } from "../../schema-validation";
import { EEmploymentStatus } from "../../workers-shared/EEmploymentStatus";
import { ELaForgeWorkerEnum } from "../../workers-shared/ELaForgeWorkerEnum";
import { benefitsClassSchema } from "../EBenefitsClass";
import { EPaySchedule } from "../EPaySchedule";
import { ETermReason } from "../ETermReason";
import { employeeAddressSchema } from "./employeeAddress";
import { employeeDatesSchema } from "./employeeDates";
import { employeeWagesSchema } from "./employeeWages";

export const employeeSchema = z
  .object({
    _id: schemes.mongoObjectId(),
    autopilot_on: z.boolean().default(false),
    benefits_class: benefitsClassSchema,
    business_ein: z.string(),
    class_code: z.number(),
    companyID: schemes.uuid(),
    complete_paperwork: z.boolean().default(false),
    createdAt: z.string(),
    default_pay_schedule: z.nativeEnum(EPaySchedule),
    deposit_one: z.any().default({}),
    deposit_two: z.any().default({}),
    doc_type: z.string(),
    doubletime_rate: z.number().min(0),
    email: schemes.email(),
    emailNotConfirmed: z.boolean(),
    employment_status: z
      .nativeEnum(EEmploymentStatus)
      .default(EEmploymentStatus.LIVE),
    everify_er_shared_referral_date_confirmation_at: z.string(),
    external_id: schemes.externalID(ZealErrorAlias.INVALID_EXTERNAL_ID.message),
    first_name: schemes.formattedName(),
    governmentPhotoIDType: z.nativeEnum(EGovernmentPhotoIDType),
    hash: z.string(),
    hellosign_company_sigId: z.string(),
    hellosign_employee_sigId: z.string(),
    hellosign_sig_req_id: z.string(),
    instant_pay_enabled: z.boolean(),
    is_943: z.boolean(),
    is_regular: z.boolean(),
    is_salary: z.boolean(),
    is_scheduleH: z.boolean(),
    kyc_pending_review: z.boolean().optional(),
    kyc_status: z.enum(EKYCStatusList).optional(),
    kyc_watchlists: z.array(z.string()),
    last_name: schemes.formattedName(),
    metadata: schemes.metadata().nullable(),
    middle_initial: z.string(),
    ocw_payeeID: z.string(),
    odo_user_id: z.string(),
    onboard_docs_faxed: z.boolean().default(false),
    onboarded: z.boolean().default(false),
    onboardedAt: z.string().optional(),
    organization: z.string(),
    overtime_rate: z.number().min(0),
    paperless_w2: z.boolean(),
    paycard_enabled: z.boolean(),
    paycard_requested: z.boolean(),
    paychex_workerID: z.string(),
    payroll_results: z.any().default([]),
    phone_number: schemes.phone(),
    pto: z.any().default([]),
    salt: z.string(),
    smarty_streets_info: z.any(),
    ss_card: z.string(),
    ssn: schemes.ssn(),
    ssn_verification_error: z.string(),
    ssn_verification_failed_reason: z.string(),
    ssn_verification_failed_validation: z.string(),
    ssn_verification_status: z.string(),
    stage: z.string(),
    state_tax_info: z.any().default({}),
    task_definition_ids: z.array(schemes.uuid()),
    tax_info: z.any().default({}),
    taxengine_workerID: schemes.mongoObjectId(),
    term_reason: z.nativeEnum(ETermReason).optional().nullable(),
    title: schemes.nonEmptyString("Title must have a minimum of 1 character"),
    updatedAt: z.string(),
    user_type: z
      .nativeEnum(ELaForgeWorkerEnum)
      .default(ELaForgeWorkerEnum.Employee),
    vac: z.any().default([]),
    veriff_id: z.string(),
    veriff_images: z.any(),
    veriff_stage: z.string(),
    workAddress: z.string(),
    working_state: z.nativeEnum(Jurisdiction),
    workLocationID: schemes.mongoObjectId(
      "workLocationID",
      ZealErrorAlias.WORK_LOCATION_NOT_FOUND.message
    ),
  })
  .merge(employeeAddressSchema)
  .merge(employeeDatesSchema)
  .merge(employeeWagesSchema);

export const employeeBaseRequirementSchema = employeeSchema
  .pick({
    email: true,
  })
  .required();

export type Employee = z.infer<typeof employeeSchema>;

export type BaseAddressEmployee = {
  address: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
};

export type BaseEmployeeWages = {
  default_wage: number;
  default_dt_wage: number;
  default_ot_wage: number;
  salary: number;
};

export type BaseEmployeeDates = {
  dob: string;
  salary_firstDate: string;
  term_date: string;
  start_date: string;
  work_week_start: string;
};

export type BaseEmployee = {
  _id?: string;
  companyID: string;
  email: string;
  external_id: string;
  first_name: string;
  middle_initial: string;
  last_name: string;
  ssn: string;
  title: string;
  phone_number: string;
  benefits_class: string;
  default_pay_schedule: EPaySchedule;
  doubletime_rate: number;
  is_943: boolean;
  is_scheduleH: boolean;
  metadata?: Record<string, unknown>;
  overtime_rate: number;
  workLocationID: string;
  is_salary: boolean;
  is_regular: boolean;
  employment_status: EEmploymentStatus;
  onboarded: boolean;
  onboardedAt: string;
  kyc_status: EKYCStatus;
  kyc_watchlists: string[];
  kyc_pending_review: boolean;
  createdAt: string;
  updatedAt: string;
  everify_er_shared_referral_date_confirmation_at: string;
  task_definition_ids: string[];
  term_reason: ETermReason;
  taxengine_workerID: string;

  // CLEANUP -- can any of these be removed?
  paychex_workerID: string;
  user_type: ELaForgeWorkerEnum;
  emailNotConfirmed: boolean;
  salt: string;
  hash: string;
  organization: string;
  autopilot_on: boolean;
  class_code: number;
  workAddress: string;
  smarty_streets_info: any;
  tax_info: any;
  state_tax_info: any;
  deposit_one: any;
  deposit_two: any;
  hellosign_company_sigId: string;
  hellosign_employee_sigId: string;
  hellosign_sig_req_id: string;
  complete_paperwork: boolean;
  veriff_id: string;
  veriff_stage: string;
  veriff_images: any;
  doc_type: string;
  governmentPhotoIDType: EGovernmentPhotoIDType;
  ss_card: string;
  payroll_results: any;
  pto: any;
  vac: any;
  stage: string;
  ocw_payeeID: string;
  odo_user_id: string;
  onboard_docs_faxed: boolean;
  business_ein: string;
  paperless_w2: boolean;
  working_state: Jurisdiction;
  paycard_enabled: boolean;
};

export type Employee2 = BaseAddressEmployee &
  BaseEmployeeWages &
  BaseEmployeeDates &
  BaseEmployee;

export const baseEmployeeSchema = {
  _id: schemes.mongoObjectId().optional(),
  companyID: schemes.uuid(),
  email: schemes.email(),
  external_id: schemes.externalID(ZealErrorAlias.INVALID_EXTERNAL_ID.message),
  first_name: schemes.formattedName(),
  middle_initial: z.string(),
  last_name: schemes.formattedName(),
  ssn: schemes.ssn(),
  title: schemes.nonEmptyString("Title must have a minimum of 1 character"),
  phone_number: schemes.phone(),
  benefits_class: benefitsClassSchema,
  default_pay_schedule: z.nativeEnum(EPaySchedule),
  doubletime_rate: z.number().min(0),
  is_943: z.boolean(),
  is_scheduleH: z.boolean(),
  metadata: schemes.metadata().optional(),
  overtime_rate: z.number().min(0),
  workLocationID: schemes.mongoObjectId(
    "workLocationID",
    ZealErrorAlias.WORK_LOCATION_NOT_FOUND.message
  ),
  is_salary: z.boolean(),
  is_regular: z.boolean(),
  employment_status: z
    .nativeEnum(EEmploymentStatus)
    .default(EEmploymentStatus.LIVE),
  onboarded: z.boolean().default(false),
  onboardedAt: z.string().optional(),
  kyc_status: z.enum(EKYCStatusList).optional(),
  kyc_watchlists: z.array(z.string()),
  kyc_pending_review: z.boolean().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  everify_er_shared_referral_date_confirmation_at: z.string(),
  task_definition_ids: z.array(schemes.uuid()),
  term_reason: z.nativeEnum(ETermReason).optional().nullable(),
  taxengine_workerID: schemes.mongoObjectId(),

  // CLEANUP -- can any of these be removed?
  paychex_workerID: z.string(),
  user_type: z
    .nativeEnum(ELaForgeWorkerEnum)
    .default(ELaForgeWorkerEnum.Employee),
  emailNotConfirmed: z.boolean(),
  salt: z.string(),
  hash: z.string(),
  organization: z.string(),
  autopilot_on: z.boolean().default(false),
  class_code: z.number(),
  workAddress: z.string(),
  smarty_streets_info: z.any(),
  tax_info: z.any().default({}),
  state_tax_info: z.any().default({}),
  deposit_one: z.any().default({}),
  deposit_two: z.any().default({}),
  hellosign_company_sigId: z.string(),
  hellosign_employee_sigId: z.string(),
  hellosign_sig_req_id: z.string(),
  complete_paperwork: z.boolean().default(false),
  veriff_id: z.string(),
  veriff_stage: z.string(),
  veriff_images: z.any(),
  doc_type: z.string(),
  governmentPhotoIDType: z.nativeEnum(EGovernmentPhotoIDType),
  ss_card: z.string(),
  payroll_results: z.any().default([]),
  pto: z.any().default([]),
  vac: z.any().default([]),
  stage: z.string(),
  ocw_payeeID: z.string(),
  odo_user_id: z.string(),
  onboard_docs_faxed: z.boolean().default(false),
  business_ein: z.string(),
  paperless_w2: z.boolean(),
  working_state: z.nativeEnum(Jurisdiction),
  paycard_enabled: z.boolean(),
};
