import { Jurisdiction } from "../jurisdictions/Jurisdiction";
import { EDepositSchedule } from "./EDepositSchedule";

export enum EPaymentDeadlineStatus {
  PENDING = "PENDING",
  LATE = "LATE",
  COMPLETED_ON_TIME = "COMPLETED_ON_TIME",
  COMPLETED_LATE = "COMPLETED_LATE",
  UNABLE_TO_PAY = "UNABLE_TO_PAY",
}

export enum EFileDeadlineStatus {
  NOT_FILED = "NOT_FILED",
  LATE = "LATE",
  FILED_ON_TIME = "FILED_ON_TIME",
  FILED_LATE = "FILED_LATE",
  UNABLE_TO_FILE = "UNABLE_TO_FILE",
}

export enum EDeadlineType {
  PAYMENT = "PAYMENT",
  FILE = "FILE",
}

export enum EFileDeadlineType {
  REGULAR = "REGULAR",
  ZERO_WAGE = "ZERO_WAGE",
}

export interface IUpdateDeadlineStatus {
  readonly status: EPaymentDeadlineStatus | EFileDeadlineStatus;
  readonly date: string;
  readonly internalUser?: string;
  readonly statusNotes: string;
  readonly deadlineID: string;
  readonly _id?: string;
}
export interface IDeadline {
  readonly deadlineID: string;
  readonly deadlineType: EDeadlineType;
  readonly quarkCompanyID: string;
  readonly reportingPeriodID: string;
  readonly deadline: string; // YYYY-MM-DD
  readonly taxType: string;
  readonly depositSchedule: EDepositSchedule;
  readonly isAccelerated: boolean;
  readonly jurisdiction: Jurisdiction;
  readonly deadlineNotes: string[];
  readonly isUserProvided: boolean;
  readonly overallStatus?: IUpdateDeadlineStatus[];
  readonly taxPaymentStatus?: EPaymentDeadlineStatus | EFileDeadlineStatus;
  readonly reason?: string;
  readonly confimationNumber?: string;
}
export interface IPaymentDeadline extends IDeadline {
  readonly deadlineType: EDeadlineType.PAYMENT;
  readonly paymentFrequency: EDepositSchedule;
  readonly overallStatus?: (IUpdateDeadlineStatus & {
    status: EPaymentDeadlineStatus;
  })[];
}

export interface IFileDeadline extends IDeadline {
  readonly deadlineType: EDeadlineType.FILE;
  readonly filingFrequency: EDepositSchedule;
  readonly form_name: string;
  readonly fileType: EFileDeadlineType;
  readonly overallStatus?: (IUpdateDeadlineStatus & {
    status: EFileDeadlineStatus;
  })[];
}

// Helper interfaces for frontend display
export interface IStatusOption {
  value: EPaymentDeadlineStatus | EFileDeadlineStatus;
  label: string;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
}

export const isDeadline = (x: any): x is IDeadline =>
  x.deadlineID !== undefined &&
  typeof x.deadlineID === "string" &&
  x.reportingPeriodID !== undefined &&
  typeof x.reportingPeriodID === "string";

export const isPaymentDeadline = (x: any): x is IPaymentDeadline =>
  isDeadline(x) && x.deadlineType === EDeadlineType.PAYMENT;

export const isFileDeadline = (x: any): x is IFileDeadline =>
  isDeadline(x) && x.deadlineType === EDeadlineType.FILE;
