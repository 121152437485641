import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const requestEmployeePaycards = (axios: IAxiosInstance) => {
  return async ({ workerId }: { readonly workerId: string }) => {
    const response = await axios.noRetry.post(
      `/api/employees/${workerId}/paycards/request`
    );

    return response; // returns 204 no data
  };
};
