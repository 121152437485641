import type { ZealApiResponse } from "@zeal/common";
import type { CreateWorkLocationByAddressRequestBody } from "./CreateWorkLocationByAddressRequestBody.jsonschema";
import type { CreateWorkLocationResponseBody } from "../createWorkLocation/CreateWorkLocationResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";
import { resolveTaxableLocation as taxLocationClient } from "../../TaxableLocationApi/resolveTaxableLocation/resolveTaxableLocation";
const PATH = "/workLocations";

type CreateWorkLocationByAddressResponse =
  ZealApiResponse<CreateWorkLocationResponseBody>;

export const createWorkLocationByAddress =
  (axios: IAxiosInstance) =>
  async (
    body: CreateWorkLocationByAddressRequestBody
  ): Promise<CreateWorkLocationByAddressResponse> => {
    const resolveTaxableLocation = taxLocationClient(axios);
    try {
      const { street1, street2, city, state, zip, ...workLocationRequest } =
        body;

      const taxLocationResponse = await resolveTaxableLocation({
        city,
        state,
        street1,
        street2,
        zip,
      });
      if (!taxLocationResponse.success) return taxLocationResponse;

      const { taxableLocationID } = taxLocationResponse.data;
      const response =
        await axios.noRetry.post<CreateWorkLocationByAddressResponse>(PATH, {
          taxableLocationID,
          ...workLocationRequest,
        });
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
