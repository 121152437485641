import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdatePushToCardUserCardRequestSchema,
  TUpdatePushToCardUserCardResponseSchema,
} from "./updatePushToCardUserCard.schema";

export const updatePushToCardUserCard = (axios: IAxiosInstance) => {
  return async (body: TUpdatePushToCardUserCardRequestSchema) => {
    const response = await axios.noRetry.patch<
      IApiResponse<TUpdatePushToCardUserCardResponseSchema>
    >(`/api/push-to-card/card`, body);

    return response.data;
  };
};
