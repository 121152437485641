import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TEnableWorkerInstantPayRequestSchema } from "./enableWorkerInstantPay.schema";

export const enableWorkerInstantPay = (axios: IAxiosInstance) => {
  return async (data: TEnableWorkerInstantPayRequestSchema) => {
    const { worker_id, worker_type } = data;
    const response = await axios.noRetry.post(
      `/api/${worker_type}/${worker_id}/instant-pay/onboard`
    );

    return response; // returns 204 no data
  };
};
