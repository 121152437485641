import { z } from "zod";

import { schemes } from "../schema-validation";
import { baseEventInput } from "./baseEvent";

export const companyOnboardingEvent = baseEventInput.merge(
  z.object({
    source: z.literal("company.onboarding"),
    companyId: schemes.uuid(),
    taskDefinitionId: schemes.uuid(),
    payload: z
      .object({
        worker: z.boolean(),
        workLocation: z.boolean(),
        federalTaxCard: z.boolean(),
      })
      .partial(),
  })
);

export type CompanyOnboardingEvent = z.infer<typeof companyOnboardingEvent>;
