import type { ZealApiResponse } from "@zeal/common";
import type { ICreateBulkContractorsRequestBody } from "./ICreateBulkContractorsRequestBody.jsonschema";
import type { CreateBulkContractorsResponseBody } from "./CreateBulkContractorsResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/contractors";

type CreateBulkContractorsResponse =
  ZealApiResponse<CreateBulkContractorsResponseBody>;

export const createBulkContractors =
  (axios: IAxiosInstance) =>
  async (body: ICreateBulkContractorsRequestBody) => {
    try {
      const response = await axios.noRetry.post<CreateBulkContractorsResponse>(
        PATH,
        body
      );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
