import { IApiResponse } from "clients/AbstractApiClient/IApiResponse";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetFormTemplateResponseJSON } from "../getFormTemplate/getFormTemplate.jsonschema";

export const getInternationalForm = (axios: IAxiosInstance) => {
  return async (params: { formTemplateID: string; spanishMode: boolean }) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetFormTemplateResponseJSON>
    >(`/api/forms/international`, {
      params,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
