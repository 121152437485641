import type { ZealApiResponse } from "@zeal/common";
import type { IVerifyCompanyMicroDepositsRequestBody } from "./IVerifyCompanyMicroDepositsRequestBody.jsonschema";
import type { IVerifyCompanyMicroDepositsResponseBody } from "./IVerifyCompanyMicroDepositsResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies/microdeposits/verify";

type VerifyCompanyMicroDepositsResponse =
  ZealApiResponse<IVerifyCompanyMicroDepositsResponseBody>;

export const verifyCompanyMicroDeposits =
  (axios: IAxiosInstance) =>
  async (body: IVerifyCompanyMicroDepositsRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<VerifyCompanyMicroDepositsResponse>(
          PATH,
          body
        );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
