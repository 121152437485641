import { z } from "zod";

export const yearErrorMessage = "Year must be between 1900 and 2200";

export const year = () =>
  z
    .union([
      z.string().regex(/^\d{4}$/, "Year must be a 4-digit number"), // Validate as a string
      z.number().int().min(1900).max(2200), // Validate as a number
    ])
    .transform((val) => (typeof val === "number" ? String(val) : val)) // Normalize to string
    .refine((val) => {
      const numYear = Number(val);
      return numYear >= 1900 && numYear <= 2200;
    }, yearErrorMessage);
