import {
  EDisbursementMethod,
  EPaySchedule,
  ETxnSpeed,
  PayrollRunStatus,
  schemes,
  zSchema,
} from "@zeal/common";

export const updatePayrollRunRequestSchema = zSchema.object({
  checks: zSchema.array(schemes.uuid()).optional(),
  companyID: schemes.companyID(),
  disbursement_method: zSchema.nativeEnum(EDisbursementMethod).optional(),
  pay_date: schemes.isoDate().optional(),
  pay_frequency: zSchema.nativeEnum(EPaySchedule).optional(),
  pay_period: zSchema.object({}).passthrough().optional(),
  payrollRunID: schemes.mongoObjectId(),
  reportingPeriodID: schemes.mongoObjectId().optional(),
  speed: zSchema.nativeEnum(ETxnSpeed).optional(),
  status: zSchema.nativeEnum(PayrollRunStatus).optional(),
});

export type TUpdatePayrollRunRequestSchema = zSchema.TypeOf<
  typeof updatePayrollRunRequestSchema
>;

export const updatePayrollRunResponseSchema = zSchema.object({});

export type TUpdatePayrollRunResponseSchema = zSchema.TypeOf<
  typeof updatePayrollRunResponseSchema
>;
