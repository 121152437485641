export const getIsEmployeeProfileInformationComplete = (employee: {
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  ssn: string;
  phone_number: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}): boolean =>
  Boolean(
    employee.first_name &&
      employee.last_name &&
      employee.email &&
      employee.dob &&
      employee.ssn &&
      employee.phone_number &&
      employee.address &&
      employee.city &&
      employee.state &&
      employee.zip
  );
