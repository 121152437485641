import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getAstraAccountBalance = (axios: IAxiosInstance) => {
  return async (params: { account_id: string }) => {
    const response = await axios.noRetry.get<
      IApiResponse<{ available_balance: number; current_balance: number }>
    >(`/api/astra/balance`, { params });

    return response.data;
  };
};
