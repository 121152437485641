import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TListAccountBalancesRequestSchema,
  TListAccountBalancesResponse,
} from "./listAccountBalances.schema";

export const listAccountBalances = (axios: IAxiosInstance) => {
  return async (params: TListAccountBalancesRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TListAccountBalancesResponse>
    >(`/api/provider-accounts/balances`, { params });

    return response.data;
  };
};
