import { IV2_Deduction } from "../deductions/IV2_Deduction";
import { IWithholding } from "../misc-taxation/IWithholding";
import { ETxnSpeed } from "../transaction-speeds";
import { EEmployeeDisbursementMethod } from "../workers-shared/EDisbursementMethod";

export const EEmployeeCheckStatus = {
  Failed: "failed",
  Pending: "pending",
  PreProcessed: "pre-processed",
  Processed: "processed",
} as const;
export type EEmployeeCheckStatus =
  (typeof EEmployeeCheckStatus)[keyof typeof EEmployeeCheckStatus];

export type TCustomerAccountTotals = Record<
  string,
  {
    gross_pay: number;
    company_cash_requirement: number;
    company_debit: number;
    two_percent_shareholder_healthcare_reimbursement: number;
  }
>;

export interface IEmployeeCheck {
  readonly first_name?: string;
  readonly last_name?: string;
  readonly middle_initial?: string;
  readonly reportingPeriod?: {
    readonly id?: string;
    readonly start?: string;
    readonly end?: string;
  };
  readonly paystub_id: string;
  readonly approval_required: boolean;
  readonly approved: boolean;
  readonly check_date: string;
  readonly employeeID: string;
  readonly companyID: string;
  status: string;
  status_detail?: string;
  readonly employerCheckID: string;
  readonly reportingPeriodID: string;
  taxengine_check_id?: string;
  readonly speed?: ETxnSpeed;
  readonly is_salary?: boolean;

  readonly gross_pay: number; // DEPRECATED
  readonly net_pay: number; // DEPRECATED
  readonly total_employee_taxes: number; // DEPRECATED
  readonly total_employer_taxes: number; // DEPRECATED

  totals: {
    gross_pay: number;
    gross_earnings: number;
    net_pay: number;
    employee_deductions: number;
    employee_garnishments: number;
    company_debit: number;
    company_cash_requirement: number;
    employer_deductions: number;
    employee_taxes: number;
    employer_taxes: number;
    employee_benefit_deductions: number;
    employer_benefit_deductions: number;
    cash_tips: number;
    two_percent_shareholder_healthcare_reimbursement: number;
    void_gross_pay?: number;
    void_gross_earnings?: number;
    void_net_pay?: number;
    void_employee_deductions?: number;
    void_employee_garnishments?: number;
    void_company_debit?: number;
    void_company_cash_requirement?: number;
    void_employer_deductions?: number;
    void_employee_taxes?: number;
    void_employer_taxes?: number;
  };

  readonly customer_account_totals: TCustomerAccountTotals;

  readonly taxes: IWithholding[];
  readonly disbursement: {
    readonly method: EEmployeeDisbursementMethod;
    readonly status:
      | "pending"
      | "settled"
      | "canceled"
      | "returned"
      | "net_pay_void"
      | "refunded"
      | null;
  };
  readonly deductions: IV2_Deduction[];

  readonly hasWageViolations?: boolean;

  readonly flsa_ot_recommendation?: boolean;
  flsa_ot_transformed?: boolean;
}
