import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreatePayrollRunRequestSchema,
  TCreatePayrollRunResponseSchema,
} from "./createPayrollRun.schema";

export const createPayrollRun = (axios: IAxiosInstance) => {
  return async (requestBody: TCreatePayrollRunRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreatePayrollRunResponseSchema>
    >(`/api/payroll-runs`, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
