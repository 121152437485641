import {
  TEqualsFilter,
  TExcludeFilter,
  TGreaterThanFilter,
  TGreaterThanOrEqualFilter,
  TIncludeFilter,
  TLessThanFilter,
  TLessThanOrEqualFilter,
} from "../bolarus";

export type CreateAttributeRequestBase = {
  companyID: string;
  name: string;
  label: string;
  description?: string;
  isRequired?: boolean;
  tooltip?: string | { [key: string]: string };
  readonly ruleSetId?: string;
  readonly responseSetBy?: "worker" | "admin";
  readonly shouldCreateTask?: boolean;
  readonly dependencyRule: TRuleSet | null;
  readonly status: "live" | "pending";
};

export type CustomAttributeType =
  | "options"
  | "percentage"
  | "dollars"
  | "integer"
  | "string"
  | "boolean"
  | "date";

export type CreateOptionsAttributeRequest = CreateAttributeRequestBase & {
  type: "options";
  options: { value: string; name: string }[];
  default: { value: string };
};
export type CreateStringAttributeRequest = CreateAttributeRequestBase & {
  type: "string";
  constraints?: {
    maxLength: number | null;
    minLength: number | null;
    pattern?: string | null;
  };
  default: { value: string };
};
export type CreateDateAttributeRequest = CreateAttributeRequestBase & {
  type: "date";
  constraints?: { min: string | null; max: string | null };
  default: { value: string };
};

// Discriminated Union for Custom Attributes
export type CreateAttributeRequest =
  | CreateOptionsAttributeRequest
  | (CreateAttributeRequestBase & {
      type: "integer" | "dollars" | "percentage";
      constraints?: { min: number | null; max: number | null };
      default: { value: number };
    })
  | (CreateAttributeRequestBase & {
      type: "boolean";
      default: { value: boolean };
    })
  | CreateStringAttributeRequest
  | CreateDateAttributeRequest;

export type ReadAttributeRequest = {
  companyID: string;
  attributeID: string;
};

export type UpdateAttributeRequest = Partial<CreateAttributeRequest> & {
  attributeID: string;
};

export type Attribute = CreateAttributeRequest & {
  attributeID: string;
  id: string;
};

export type DeleteAttributeRequest = {
  companyID: string;
  attributeID: string;
  type: CustomAttributeType;
};

export type AggregateByTypeRequest = {
  companyID: string;
  type: CustomAttributeType;
};

export const CUSTOM_ATTRIBUTE_ROUTES = {
  QUERY_ATTRIBUTES: "/attributes/query",
  QUERY_WORKER_ATTRIBUTES: "/attributes/worker",
  CREATE_ATTRIBUTE: "/attributes",
  READ_ATTRIBUTE: "/attributes/:attributeID",
  UPDATE_ATTRIBUTE: "/attributes/:attributeID",
  DELETE_ATTRIBUTE: "/attributes/:attributeID",
  AGGREGATE_ATTRIBUTES: "/attributes/aggregate/:type",
} as const;

export const AttributesTargetModel = {
  EMPLOYEE: "employees",
  CONTRACTOR: "contractors",
  SHIFT: "shifts",
  EMPLOYEE_CHECK: "employeeCheck",
} as const;
export type AttributesTargetModel =
  (typeof AttributesTargetModel)[keyof typeof AttributesTargetModel];

export type CreateOrUpdateApplyAttributeRequest = {
  target_model: AttributesTargetModel;
  targetID: string;
  attributeID: string;
  value?: string;
};

export type ApplyAttributResponse = {
  target_model: AttributesTargetModel;
  targetID: string;
  attributes: { attributeID: string; value: string }[];
};

export type CreateOrUpdateApplyAttributesRequest = {
  target_model: AttributesTargetModel;
  targetID: string;
  attributes: { attributeID: string; value: string }[];
  companyID: string;
};

export type ReadApplyAttributeRequest = {
  target_model: AttributesTargetModel;
  targetID: string;
  attributeID: string;
};

export type DeleteApplyAttributeRequest = {
  target_model: AttributesTargetModel;
  targetID: string;
  attributeID: string;
};

export type GetCurrentStateRequest = {
  companyID: string;
  target_model: AttributesTargetModel;
  targetID: string;
};

export type GetCurrentStateResponse = {
  [attributeID: string]: CreateAttributeRequest & {
    value: string | number | boolean;
    attributeID: string;
  };
};

export const APPLY_ATTRIBUTE_ROUTES = {
  CREATE_OR_UPDATE_APPLY_ATTRIBUTES: "/applyattributes",
  READ_APPLY_ATTRIBUTE: "/applyattributes/:target_model/:targetID/:attributeID",
  DELETE_APPLY_ATTRIBUTE:
    "/applyattributes/:target_model/:targetID/:attributeID",
  GET_CURRENT_STATE: "/attributestate/:target_model/:targetID",
} as const;

export type TEmployeeCustomAttribute = {
  attributeID: string;
  value: number | string | boolean | null;
};

export type TAttributeIncludeFilter = TIncludeFilter<string[], "value">;
export type TAttributeExcludeFilter = TExcludeFilter<string[], "value">;

export type TAttributeEqualsFilter = TEqualsFilter<number | boolean, "value">;
export type TAttributeGreaterThanFilter = TGreaterThanFilter<number, "value">;
export type TAttributeLessThanFilter = TLessThanFilter<number, "value">;
export type TAttributeGreaterThanOrEqualFilter = TGreaterThanOrEqualFilter<
  number,
  "value"
>;
export type TAttributeLessThanOrEqualFilter = TLessThanOrEqualFilter<
  number,
  "value"
>;

export type TAttributeFilter = (
  | TAttributeEqualsFilter
  | TAttributeGreaterThanFilter
  | TAttributeLessThanFilter
  | TAttributeGreaterThanOrEqualFilter
  | TAttributeLessThanOrEqualFilter
  | TAttributeIncludeFilter
  | TAttributeExcludeFilter
) & {
  attributeID: string;
  attributeName: string;
  filterID?: string;
};

export type TLogicalOperator = "AND" | "OR";
export interface IRuleNode {
  filterID: string;
  type: "filter" | "group";
}
export interface IFilterRule extends IRuleNode {
  type: "filter";
  filter: TAttributeFilter;
}
export interface IGroupRule extends IRuleNode {
  type: "group";
  operator: TLogicalOperator;
  children: Array<IFilterRule | IGroupRule>;
}

export type TRuleSet = IFilterRule | IGroupRule;

// Routes for the rule set API
export const RULE_SET_ROUTES = {
  CREATE_RULE_SET: "/rulesets", // POST
  GET_RULE_SET: "/rulesets/:companyID/:ruleSetID", // GET
  LIST_RULE_SETS: "/rulesets/:companyID", // GET
  ADD_FILTER_RULE: "/rulesets/:companyID/:ruleSetID/filters", // POST
  ADD_GROUP_RULE: "/rulesets/:companyID/:ruleSetID/groups", // POST
  UPDATE_FILTER_RULE: "/rulesets/:companyID/:ruleSetID/filters/:filterID", // PUT
  UPDATE_GROUP_OPERATOR: "/rulesets/:companyID/:ruleSetID/groups/:groupID", // PUT
  MOVE_RULE: "/rulesets/:companyID/:ruleSetID/rules/:ruleID/move", // PUT
  REMOVE_RULE: "/rulesets/:companyID/:ruleSetID/rules/:ruleID", // DELETE
  EVALUATE_RULE_SET: "/rulesets/:companyID/:ruleSetID/evaluate", // POST
  DELETE_RULE_SET: "/rulesets/:companyID/:ruleSetID", // DELETE
} as const;

export interface CreateRuleSetRequest {
  companyID: string;
  // ruleSetID: string;
  name: string;
}

export interface CreateRuleSetResponse {
  success: boolean;
  message: string;
  ruleSetID?: string;
}

export interface GetRuleSetRequest {
  companyID: string;
  ruleSetID: string;
}

export interface GetRuleSetResponse {
  success: boolean;
  ruleSet?: {
    name: string;
    ruleSetID: string;
    ruleSet: TRuleSet;
  };
  message?: string;
}

export interface ListRuleSetsRequest {
  companyID: string;
}

export interface ListRuleSetsResponse {
  success: boolean;
  ruleSets: string[];
}

export interface AddFilterRuleRequest {
  companyID: string;
  ruleSetID: string;
  parentId: string | null;
  filter: TAttributeFilter | TAttributeFilter[];
}

export interface AddFilterRuleResponse {
  success: boolean;
  message: string;
  filterID?: string;
}

export interface AddGroupRuleRequest {
  companyID: string;
  ruleSetID: string;
  parentId: string | null;
  operator: TLogicalOperator;
}

export interface AddGroupRuleResponse {
  success: boolean;
  message: string;
  groupID?: string;
}

export interface UpdateFilterRuleRequest {
  companyID: string;
  ruleSetID: string;
  filterID: string;
  filter: TAttributeFilter | TAttributeFilter[];
}

export interface UpdateFilterRuleResponse {
  success: boolean;
  message: string;
}

export interface UpdateGroupOperatorRequest {
  companyID: string;
  ruleSetID: string;
  groupID: string;
  operator: TLogicalOperator;
}

export interface UpdateGroupOperatorResponse {
  success: boolean;
  message: string;
}

export interface MoveRuleRequest {
  companyID: string;
  ruleSetID: string;
  ruleID: string;
  newParentId: string | null;
  index?: number;
}

export interface MoveRuleResponse {
  success: boolean;
  message: string;
}

export interface RemoveRuleRequest {
  companyID: string;
  ruleSetID: string;
  ruleID: string;
}

export interface RemoveRuleResponse {
  success: boolean;
  message: string;
}

export interface EvaluateRuleSetRequest {
  companyID: string;
  ruleSetID: string;
  values: { [attributeID: string]: any };
}

export interface EvaluateRuleSetResponse {
  success: boolean;
  result: boolean;
}

export interface DeleteRuleSetRequest {
  companyID: string;
  ruleSetID: string;
}

export interface DeleteRuleSetResponse {
  success: boolean;
  message: string;
}
