import { EKybStatus } from "../kyb/EKybStatus";
import { LegalStructure } from "../odo/IOdoCompany";

export enum ECustomerAccountStatus {
  INITIAL_ONBOARDING = "initial_onboarding",
  PAYMENT_PERMISSION_PENDING = "payment_permission_pending",
  LIVE = "live",
  OFFBOARDED = "offboarded",
}

export enum EOwnerType {
  "Authorized Signer" = "authorized_signer",
  "Beneficial Owner" = "beneficial_owner",
}

export type BusinessOwner = {
  readonly first_name?: string;
  readonly last_name?: string;
  readonly email?: string;
  readonly ssn?: string;
  readonly dob?: string;
  readonly title?: string;
  readonly ownership_percentage?: number;
  readonly owner_type?: EOwnerType;
  readonly address?: string;
  readonly address_line2?: string;
  readonly city?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly ssn_verification_status?: string;
  readonly ssn_verification_error?: string;
};

export interface ICustomerAccount {
  readonly bank_account_verified: boolean;
  readonly business_address?: string;
  readonly business_city?: string;
  readonly business_name: string;
  readonly business_owner?: BusinessOwner;
  readonly business_state?: string;
  readonly business_zip?: string;
  readonly code: string;
  readonly company_id: string;
  readonly customer_account_id: string;
  readonly ein?: string;
  readonly email?: string;
  readonly funding_source_id?: string;
  readonly kyb_status?: EKybStatus;
  readonly legal_structure?: LegalStructure;
  readonly odo_user_id?: string;
  readonly partner_id: string;
  readonly phone?: string;
  readonly status: ECustomerAccountStatus;
  readonly onboarded: boolean;
}
