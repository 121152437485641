import { z } from "zod";
import { schemes } from "../../schema-validation";
import { PaycardTransactionStatus } from "../PaycardTransactionStatus";
import { PaycardTransactionType } from "../PaycardTransactionType";

export const paycardTransaction = z.object({
  amount: z.string(),
  date: z.string(),
  description: z.string(),
  external_transaction_ids: z.array(z.string()),
  paycard_id: schemes.uuid(),
  status: z.nativeEnum(PaycardTransactionStatus),
  trace_id: z.string(),
  type: z.nativeEnum(PaycardTransactionType),
  paycard_transaction_id: schemes.uuid(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
});

export type PaycardTransaction = z.infer<typeof paycardTransaction>;
