export const EDisbursementMethod = {
  AVAILABLE_PAY: "available_pay",
  DIRECT_DEPOSIT: "direct_deposit",
  DIRECT_DEPOSIT_SAME_DAY: "direct_deposit_same_day",
  DOWNLOAD_CHECK: "download_check",
  PAYCARD: "paycard",
  PHYSICAL_CHECK: "physical_check",
  PREPAID: "prepaid",
} as const;

export type EDisbursementMethod =
  (typeof EDisbursementMethod)[keyof typeof EDisbursementMethod];

export type EEmployeeDisbursementMethod = EDisbursementMethod;

export type EContractorDisbursementMethod = Exclude<
  EDisbursementMethod,
  "download_check"
>;

export const DISBURSEMENT_METHODS: ReadonlyArray<EDisbursementMethod> = [
  EDisbursementMethod.AVAILABLE_PAY,
  EDisbursementMethod.DIRECT_DEPOSIT,
  EDisbursementMethod.DIRECT_DEPOSIT_SAME_DAY,
  EDisbursementMethod.DOWNLOAD_CHECK,
  EDisbursementMethod.PAYCARD,
  EDisbursementMethod.PHYSICAL_CHECK,
  EDisbursementMethod.PREPAID,
];

export const EMPLOYEE_DISBURSEMENT_METHODS: ReadonlyArray<EEmployeeDisbursementMethod> =
  [
    EDisbursementMethod.AVAILABLE_PAY,
    EDisbursementMethod.DIRECT_DEPOSIT,
    EDisbursementMethod.DIRECT_DEPOSIT_SAME_DAY,
    EDisbursementMethod.DOWNLOAD_CHECK,
    EDisbursementMethod.PAYCARD,
    EDisbursementMethod.PHYSICAL_CHECK,
    EDisbursementMethod.PREPAID,
  ];

export const CONTRACTOR_DISBURSEMENT_METHODS: ReadonlyArray<EContractorDisbursementMethod> =
  [
    EDisbursementMethod.AVAILABLE_PAY,
    EDisbursementMethod.DIRECT_DEPOSIT,
    EDisbursementMethod.DIRECT_DEPOSIT_SAME_DAY,
    EDisbursementMethod.PAYCARD,
    EDisbursementMethod.PHYSICAL_CHECK,
    EDisbursementMethod.PREPAID,
  ];

export const CREDITED_DISBURSEMENT_METHODS: ReadonlyArray<EDisbursementMethod> =
  [
    EDisbursementMethod.DIRECT_DEPOSIT,
    EDisbursementMethod.DIRECT_DEPOSIT_SAME_DAY,
    EDisbursementMethod.PAYCARD,
  ];

export const NON_DEBITABLE_DISBURSEMENT_METHODS: ReadonlyArray<EDisbursementMethod> =
  [
    EDisbursementMethod.DOWNLOAD_CHECK,
    EDisbursementMethod.PHYSICAL_CHECK,
    EDisbursementMethod.PREPAID,
  ];

export const SAME_DAY_DISBURSEMENT_METHODS: ReadonlyArray<EDisbursementMethod> =
  [EDisbursementMethod.DIRECT_DEPOSIT_SAME_DAY];
