import { z } from "zod";

import { bankAccountSchema } from "../bank-accounts/schemas/bankAccount.schema";
import { contractorSchema } from "../contractors/contractorSchema";
import { EPaycardOnboardingStatus } from "../pay-cards";
import { schemes } from "../schema-validation";
import { userAccountSchema } from "../users/schemas/users-xes";
import { baseEventInput } from "./baseEvent";

const contractorBankAccountEventSchema = z
  .object({
    category: z.literal("bankAccount"),
  })
  .merge(bankAccountSchema.partial());

const contractorProfileEventSchema = z
  .object({
    category: z.literal("profile"),
  })
  .merge(contractorSchema.partial())
  .merge(
    z.object({
      hasKYCStatusChange: z.boolean().optional(),
      newPaycardOnboardingStatus: z
        .nativeEnum(EPaycardOnboardingStatus)
        .optional(),
      paycardRequestQueued: z.boolean().optional(),
    })
  );

const contractorUserAccountSchema = z
  .object({
    category: z.literal("userAccount"),
  })
  .merge(userAccountSchema);

// cannot simply merge these schemas because bank account and contractor each have a property named "type"
export const contractorEventPayloadSchema = z.discriminatedUnion("category", [
  contractorProfileEventSchema,
  contractorBankAccountEventSchema,
  contractorUserAccountSchema,
]);

export const contractorEvent = baseEventInput.merge(
  z.object({
    source: z.literal("contractors"),
    contractorId: schemes.mongoObjectId(),
    companyId: schemes.uuid(),
    payload: contractorEventPayloadSchema,
  })
);

export type ContractorEvent = z.infer<typeof contractorEvent>;
