import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

type TPostPushNotificationTokenRequestSchema = {
  pushNotificationToken: string;
  deviceType: "ios" | "android";
};

const URL = `/api/me/notifications/push-notifications-token`;

export const postPushNotificationToken = (axios: IAxiosInstance) => {
  return async (requestBody: TPostPushNotificationTokenRequestSchema) => {
    const response = await axios.noRetry.post<IApiResponse<boolean>>(
      URL,
      requestBody
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
