import type { IGenEmployerLoginLinkRequestBody } from "./IGenEmployerLoginLinkRequestBody.jsonschema";
import type { IGenEmployerLoginLinkResponse } from "./IGenEmployerLoginLinkResponse";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/getAuthLink";

type ErrorResponse = { readonly success: false } & unknown;

export const genEmployerLoginLink =
  (axios: IAxiosInstance) => async (body: IGenEmployerLoginLinkRequestBody) => {
    try {
      const response = await axios.noRetry.post<
        IGenEmployerLoginLinkResponse | ErrorResponse
      >(PATH, body);
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
