import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TSubmitWorkerAccountInfo } from "./submitWorkerAccountInfo.schema";

export const submitWorkerAccountInfo = (axios: IAxiosInstance) => {
  return async (body: TSubmitWorkerAccountInfo) => {
    const response = await axios.noRetry.post<ZealApiResponse<any>>(
      "/api/submitWorkerAccountInfo",
      {
        email: body.email,
        password: body.password,
        first_name: body.first_name,
        last_name: body.last_name,
      },
      {
        headers: { "x-exchange-token": body.accessToken },
      }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
