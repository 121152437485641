import dayjs from "dayjs";
import { z } from "zod";
import { assertValidDOB } from "../assertions";
import { assertValidDate } from "../assertions/assertValidDate";

export const dob = (errmsg?: string) =>
  z
    .string()
    .superRefine((val, ctx) => {
      assertValidDate(val, ctx, errmsg ?? "DOB is not a valid date");
      assertValidDOB(val, ctx, errmsg);
    })
    .transform((val) => dayjs(val).format("YYYY-MM-DD"));
