export const EWorkerInformationSection = {
  ACCOUNT_INFO: "account_info",
  SURVEY: "survey",
  CUSTOM_PAPERWORK: "paperwork",
  PROFILE_INFO: "profile_info",
  BANK_INFO: "bank_info",
  PAYCARD: "paycard",
  INSTANT_PAY: "instant_pay",
} as const;

export type TWorkerInformationSection =
  (typeof EWorkerInformationSection)[keyof typeof EWorkerInformationSection];

export const contractorShareableLinkSections: TWorkerInformationSection[] = [
  EWorkerInformationSection.BANK_INFO,
  EWorkerInformationSection.PAYCARD,
  EWorkerInformationSection.INSTANT_PAY,
];
