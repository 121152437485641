import { TApiPaginatedRequest } from "clients/AbstractApiClient/IApiRequest";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TApiPaginatedResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetWalletTransactionsRequestSchema,
  TGetWalletTransactionsResponseSchema,
} from "./getWalletTransactions.schema";

export const getWalletTransactions = (axios: IAxiosInstance) => {
  return async (
    data: TApiPaginatedRequest<TGetWalletTransactionsRequestSchema>
  ) => {
    const { wallet_id, worker_id, worker_type, ...rest } = data;

    const response = await axios.noRetry.get<
      TApiPaginatedResponse<TGetWalletTransactionsResponseSchema>
    >(`/api/${worker_type}/${worker_id}/wallets/${wallet_id}/transactions`, {
      params: rest,
    });

    return response.data;
  };
};
