import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPayCardTransactionRequestSchema,
  TGetPayCardTransactionResponseSchema,
} from "./getPaycardTransaction.schema";

export const getPayCardTransaction = (axios: IAxiosInstance) => {
  return async (params: TGetPayCardTransactionRequestSchema) => {
    const { paycard_id, transaction_id } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardTransactionResponseSchema>
    >(`/api/pay-cards/${paycard_id}/transactions/${transaction_id}`);

    return response.data;
  };
};
