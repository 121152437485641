import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { TGetPayrollRunEmployeesRequest } from "./getPayrollRunEmployees.schema";

export const getPayrollRunEmployees = (axios: IAxiosInstance) => {
  return async (params: TGetPayrollRunEmployeesRequest) => {
    const { payroll_run_id, ...rest } = params;

    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetPayrollRunEmployeesResponse"]
    >(`/api/payroll-runs/${payroll_run_id}/employees`, {
      params: rest,
    });

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
