import { EEmploymentStatus, schemes, zSchema } from "@zeal/common";

export const postKycSummaryRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  employment_status: zSchema.nativeEnum(EEmploymentStatus),
  kyc_status: zSchema.enum(["all", "approved", "failed"]).optional(),
  ssn_verification_status: zSchema
    .enum(["all", "approved", "declined"])
    .optional(),
  media_type: zSchema.enum(["csv"]),
});

export type TPostKycSummaryRequestSchema = zSchema.infer<
  typeof postKycSummaryRequestSchema
>;

export const postKycSummaryResponseSchema = zSchema.object({
  created_at: zSchema.string(),
  job_id: zSchema.string(),
  status: zSchema.enum(["pending", "completed"]),
});

export type TPostKycSummaryResponseSchema = zSchema.infer<
  typeof postKycSummaryResponseSchema
>;
