export enum EParamType {
  string = "string",
  float = "float",
  percentage = "percentage",
  integer = "integer",
  enum = "enum",
  boolean = "boolean",
  dollars = "dollars",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  base64 = "base64",
}
