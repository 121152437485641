import type { ZealApiResponse } from "@zeal/common";
import type { ICreateBulkEmployeesRequestBody } from "./ICreateBulkEmployeesRequestBody.jsonschema";
import type { ICreateBulkEmployeesResponseBody } from "./ICreateBulkEmployeesResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/employees";

type CreateBulkEmployeesResponse =
  ZealApiResponse<ICreateBulkEmployeesResponseBody>;

export const createBulkEmployees =
  (axios: IAxiosInstance) => async (body: ICreateBulkEmployeesRequestBody) => {
    try {
      const response = await axios.noRetry.post<CreateBulkEmployeesResponse>(
        PATH,
        body
      );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
