import { z } from "zod";

import { employeeSchema } from "../employees/schemas/baseEmployee";
import { schemes } from "../schema-validation";
import { baseEventInput } from "./baseEvent";

export const kycStatusEventPayloadSchema = employeeSchema.partial();

const contractorKYCStatusEvent = baseEventInput.merge(
  z.object({
    source: z.literal("contractors"),
    contractorId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
  })
);

const employeeKYCStatusEvent = baseEventInput.merge(
  z.object({
    source: z.literal("employees"),
    employeeId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
  })
);

export const kycStatusEvent = z.discriminatedUnion("source", [
  contractorKYCStatusEvent,
  employeeKYCStatusEvent,
]);

export type TContractorKYCStatusEvent = z.infer<
  typeof contractorKYCStatusEvent
>;
export type TEmployeeKYCStatusEvent = z.infer<typeof employeeKYCStatusEvent>;
export type TKYCStatusEvent = z.infer<typeof kycStatusEvent>;
