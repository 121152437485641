import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type GetDoesUserExistResponse = IApiResponse<{ exists: boolean }>;

export const getDoesUserExist = (axios: IAxiosInstance) => {
  return async (options: { email: string }) => {
    const response = await axios.noRetry.get<GetDoesUserExistResponse>(
      `/api/user-exists?email=${encodeURIComponent(options.email)}`
    );

    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to get current user accounts");
    }
    return data;
  };
};
