import { AxiosResponse } from "axios";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import {
  buildResponseBase,
  IACHDebitSubmissionRequest,
  IPaymentSubmission,
} from "@zeal/common";

export const postACHDebitPayment =
  (axios: IAxiosInstance) => async (obj: IACHDebitSubmissionRequest[]) => {
    const url = "/private/api/compass/addACHDebitPayment";

    const res = await axios.noRetry.request<
      IPaymentSubmission[],
      AxiosResponse<IPaymentSubmission[]>,
      IACHDebitSubmissionRequest[]
    >({
      method: "POST",
      url,
      data: obj,
    });

    return buildResponseBase({ status: res.status, data: res.data });
  };
