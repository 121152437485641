/**
 * "INTERNAL_" prefix added on purpose for readability when partners are consuming webhooks
 */
export const EI9AdminReviewStatus = {
  PENDING_REVIEW: "PENDING_REVIEW",
  CHANGES_REQUESTED: "CHANGES_REQUESTED",
  APPROVED: "APPROVED",
} as const;

export type EI9AdminReviewStatus =
  (typeof EI9AdminReviewStatus)[keyof typeof EI9AdminReviewStatus];

export interface II9AdminReview {
  status: EI9AdminReviewStatus;
  requested_changes?: string;
  reviewed_on?: string;
  reviewed_by?: string;
  archived?: boolean;
  archive_date?: string;
}
