import { PayCardStatus, schemes, zSchema } from "@zeal/common";

export const updatePaycardRequestSchema = zSchema.object({
  paycard_id: schemes.uuid(),
  pin: schemes.cardPin().optional(),
  status: zSchema.nativeEnum(PayCardStatus).optional(),
});

export type TUpdatePaycardRequestSchema = zSchema.infer<
  typeof updatePaycardRequestSchema
>;

export const updatePaycardResponseSchema = zSchema.object({
  external_pay_card_id: zSchema.string(),
  odo_user_id: zSchema.string(),
  pay_card_id: zSchema.string(),
  provider_account_id: zSchema.string(),
  status: zSchema.nativeEnum(PayCardStatus),
});

export type TUpdatePaycardResponseSchema = zSchema.infer<
  typeof updatePaycardResponseSchema
>;
