import {
  APPLY_ATTRIBUTE_ROUTES,
  GetCurrentStateRequest,
  GetCurrentStateResponse,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const getCurrentState = (axios: IAxiosInstance) => {
  return async (
    data: GetCurrentStateRequest
  ): Promise<GetCurrentStateResponse> => {
    const response = await axios.noRetry.get<
      IApiResponse<GetCurrentStateResponse>
    >(
      APPLY_ATTRIBUTE_ROUTES.GET_CURRENT_STATE.replace(
        ":target_model",
        data.target_model
      ).replace(":targetID", data.targetID),
      { params: data }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
