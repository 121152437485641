import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getCardInfo = (axios: IAxiosInstance) => {
  return async (params: { worker_id: string }) => {
    const { worker_id } = params;

    const response = await axios.noRetry.get<IApiResponse<any>>(
      `/api/push-to-card/card-info?worker_id=${worker_id}`
    );

    return response.data;
  };
};
