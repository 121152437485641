import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetContractorsResponseSchema } from "./getContractors.schema";

type Options = {
  isOnboarded?: boolean;
};

export const getContractors = (axios: IAxiosInstance) => {
  return async (options?: Options) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetContractorsResponseSchema>
    >(`/api/getMyContractors`, {
      params: { isOnboarded: options?.isOnboarded },
    });
    const data = response.data;

    return data;
  };
};
