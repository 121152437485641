import { z } from "zod";

const getPaycardEnrollmentActionRequiredResponseSchema = z.object({
  contractors: z.boolean(),
  workers: z.boolean(),
});

export type TGetPaycardEnrollmentActionRequiredResponse = z.infer<
  typeof getPaycardEnrollmentActionRequiredResponseSchema
>;
