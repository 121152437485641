import type { ZealApiResponse } from "@zeal/common";
import type { IGetContractorInfoRequestParams } from "./IGetContractorInfoRequestParams.jsonschema";
import type { GetContractorInfoResponseBody } from "./GetContractorInfoResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/contractors";

type GetContractorInfoResponse = ZealApiResponse<GetContractorInfoResponseBody>;

export const getContractorInfo =
  (axios: IAxiosInstance) =>
  async (params: IGetContractorInfoRequestParams) => {
    try {
      const response = await axios.noRetry.get<GetContractorInfoResponse>(
        PATH,
        { params }
      );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
