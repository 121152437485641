import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TUploadPayCardArtResponseSchema } from "./uploadPayCardArt.schema";

export const uploadPayCardArt = (axios: IAxiosInstance) => {
  return async (data: FormData) => {
    const response = await axios.noRetry.post<
      IApiResponse<TUploadPayCardArtResponseSchema>
    >(`/api/pay-cards/art`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  };
};
