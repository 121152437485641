import { schemes, zSchema } from "@zeal/common";

export const getWorkerAstraCardRequestSchema = zSchema.object({
  workerID: schemes.mongoObjectId(),
});

export type TGetWorkerAstraCardRequestSchema = zSchema.infer<
  typeof getWorkerAstraCardRequestSchema
>;

export const getWorkerAstraCardResponseSchema = zSchema.object({
  id: zSchema.string(),
  card_company: zSchema.string(),
  card_type: zSchema.enum(["debit", "credit", "prepaid", "unknown"]),
  exp_date: zSchema.string(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  last_four: zSchema.string(),
});

export type TGetWorkerAstraCardResponseSchema = zSchema.infer<
  typeof getWorkerAstraCardResponseSchema
>;
