import { z } from "zod";

import { employeeBankAccountTaskSchema } from "../bank-accounts/schemas/bankAccount.schema";
import { employeeSchema } from "../employees/schemas/baseEmployee";
import { w4Schema } from "../forms/schemas/w4";
import { EPaycardOnboardingStatus } from "../pay-cards";
import { schemes } from "../schema-validation";
import { userAccountSchema } from "../users/schemas/users-xes";
import { baseEventInput } from "./baseEvent";

export const employeeEventPayloadSchema = employeeSchema
  .merge(userAccountSchema)
  .merge(employeeBankAccountTaskSchema)
  .merge(w4Schema)
  .merge(
    z.object({
      hasKYCStatusChange: z.boolean().optional(),
      newPaycardOnboardingStatus: z
        .nativeEnum(EPaycardOnboardingStatus)
        .optional(),
      paycardRequestQueued: z.boolean().optional(),
    })
  )
  .partial();

export const employeeEvent = baseEventInput.merge(
  z.object({
    source: z.literal("employees"),
    employeeId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
    closeCompanyEmployeeTaskForId: z.string().nullish(),
    taskDefinitionIds: z.array(schemes.uuid()),
    payload: employeeEventPayloadSchema,
  })
);

export type EmployeeEvent = z.infer<typeof employeeEvent>;
