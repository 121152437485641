import { z } from "zod";

const companySchema = z.object({
  email: z.string(),
  employer_address: z.string().optional(),
  employer_city_state_address: z.string().optional(),
  employer_name: z.string().optional(),
  business_phone: z.string().optional(),
  business_ein: z.string().optional(),
  logo_name: z.string().optional(),
  logo_url: z.string().optional(),
  paycards_enabled: z.boolean().optional(),
  quarkCompanyID: z.string().optional(),
  white_label_id: z.string().optional(),
});

export type Company = z.infer<typeof companySchema>;
