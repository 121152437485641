import dayjs from "dayjs";
import dayjsBusinessTime from "dayjs-business-time";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { buildAllFederalBankingHolidays } from "./holidays/holidays";

dayjs.extend(utcPlugin);
dayjs.extend(weekday);
dayjs.extend(quarterOfYear);
dayjs.extend(dayjsBusinessTime);
dayjs.extend(timezone);

dayjs.setHolidays(
  buildAllFederalBankingHolidays({
    currentYear: new Date().getFullYear(),
    shiftSaturdayHolidays: false,
    shiftSundayHolidays: true,
  }).map((h) => h.dateString)
);

dayjs.tz.setDefault("America/Los_Angeles");

export default dayjs;
