import { z } from "zod";
import { MediaType } from "../enums";
import { RouteMap } from "./customerReporting";
import { deductionSchema } from "./deductions";
import { shiftSchema } from "./shift";

const paystubReportingPeriod = z.object({
  start: z.string(),
  end: z.string(),
  pay_schedule: z.string(),
});

const paystubTaxSchema = z.object({ name: z.string(), amount: z.string() });

const ytdDeductionsSchema = z
  .object({
    total: z.string(),
  })
  .and(z.record(z.string(), z.string()));

const paystubYtdsSchema = z.object({
  gross_pay: z.string(),
  employee_taxes: z.string(),
  net_pay: z.string(),
  employee_deductions: z.string(),
  deductions: ytdDeductionsSchema,
  withholdings: z.record(z.string(), z.string()),
  cash_tips: z.string(),
  two_percent: z.string(),
});

const paystubTotalsSchema = z.object({
  gross_earnings: z.string(),
  gross_pay: z.string(),
  net_pay: z.string(),
  employee_garnishments: z.string(),
  company_debit: z.string(),
  company_cash_requirement: z.string(),
  employee_deductions: z.string(),
  employer_deductions: z.string(),
  employee_taxes: z.string(),
  employer_taxes: z.string(),
  cash_tips: z.string(),
  two_percent_shareholder_healthcare_reimbursement: z.string(),
});

const paystubDeductionsSchema = z.object({
  deduction_type: z.string(),
  base_deduction_type: z.string(),
  value: z.string(),
});

const paystubShiftCorrectionSchema = z.object({
  type: z.string(),
  rate: z.string(),
  earned: z.string(),
});

const paystubSchema = z.object({
  employeeID: z.string(),
  full_name: z.string(),
  address: z.string(),
  ssn: z.string(),
  city_state_address: z.string(),
  has_employee_checks: z.boolean(),
  shifts: z.record(z.string(), z.array(shiftSchema)).nullable(),
  reporting_period: paystubReportingPeriod,
  ytds: paystubYtdsSchema,
  check_date: z.string(),
  total_employee_deductions: z.string(),
  deductions: z.array(paystubDeductionsSchema),
  net_pay: z.string(),
  gross_pay: z.string(),
  total_employee_taxes: z.string(),
  totals: paystubTotalsSchema,
  taxes: z.array(paystubTaxSchema),
  disbursement: z.string(),
  accrualPoliciesWithinReportingPeriod: z.array(
    z.object({
      policyType: z.string(),
      balance: z.number(),
      accrued: z.number(),
      used: z.number().nullable(),
      usedYTD: z.number(),
      accruedYTD: z.number(),
    })
  ),
  corrections: z.array(paystubShiftCorrectionSchema).nullable(),
  hasOregonWithholding: z.boolean().default(false).optional(),
  oregonBIN: z.string().nullable().optional(),
});

export const paystubSchemaArray = z.array(paystubSchema);

export type PaystubResponse = z.infer<typeof paystubSchema>;
export type PaystubMappingResponse = {
  kind: RouteMap.paystub;
  media_type: MediaType.Pdf;
  paystubs: { [check_date: string]: PaystubResponse[] };
};
export type PaystubShape = {
  media_type: MediaType.Pdf;
  data: Buffer;
};

export type PaystubYTD = z.infer<typeof paystubYtdsSchema>;
export type EmployeeIDPaystubMapping = {
  quark_ids: string[];
  la_forge_ids: string[];
  quark_mapping: Record<string, string>;
};
export type PaystubDeductions = {
  readonly employeeID: string;
  readonly taxengine_check_id: string;
  readonly deductions: {
    name: string;
    employee_contribution_amount: number;
    employer_contribution_amount: number;
  }[];
  readonly total_deductions: {
    readonly employee_contribution_amount: number;
    readonly employer_contribution_amount: number;
  };
};
export type PaystubDeductionsMap = Record<
  string,
  Omit<PaystubDeductions, "employeeID">
>;

const paystubWithholdingsSchema = z.object({
  amount: z.number(),
  taxType: z.string(),
  name: z.string(),
  payer: z.enum(["EE", "ER"]),
});

const paystubCheckSchema = z.object({
  id: z.string(),
  pay_date: z.string(),
  employeeID: z.string(),
  earnings: z.object({
    amount: z.number(),
    location: z.object({
      street1: z.string(),
      street2: z.string(),
      city: z.string(),
      state: z.string(),
      zip: z.string(),
    }),
    payType: z.string(),
    name: z.string(),
  }),
  withholdings: paystubWithholdingsSchema,
  deductions: z.array(deductionSchema),
});

const paystubCheckAccrualsSchema = z.object({
  companyID: z.string().optional(),
  checks: paystubCheckSchema.optional(),
  name: z.string().optional(),
  message: z.string().optional(),
});

export type PaystubCheckAccruals = z.infer<typeof paystubCheckAccrualsSchema>;
export type PaystubWithholdings = z.infer<typeof paystubWithholdingsSchema>;
