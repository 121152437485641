import { z } from "zod";

export const submitWorkerAccountInfoSchema = z.object({
  accessToken: z.string(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  password: z.string(),
});

export type TSubmitWorkerAccountInfo = z.infer<
  typeof submitWorkerAccountInfoSchema
>;
