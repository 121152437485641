import dayjs from "dayjs";
import { z } from "zod";
import { ZealErrorAlias } from "../../errors";

export const assertValidDOB = (
  value: string,
  ctx: z.RefinementCtx,
  errmsg?: string
) => {
  const dob = dayjs(value).format("YYYY-MM-DD");
  const oldest = dayjs().subtract(115, "year").format("YYYY-MM-DD");
  const youngest = dayjs().subtract(14, "year").format("YYYY-MM-DD");

  if (dob < oldest || dob > youngest) {
    ctx.addIssue({
      code: "custom",
      message: errmsg ?? ZealErrorAlias.INVALID_DOB.message,
    });
    return z.NEVER;
  }

  return z.OK;
};
