export enum EKYCStatus {
  APPROVED = "Approved",
  DENIED = "Denied",
  EXCEPTION = "Exception",
  MANUAL_REVIEW = "Manual Review",
  STEP_UP = "Step Up Required",
}

export const EKYCStatusList = [
  EKYCStatus.APPROVED,
  EKYCStatus.DENIED,
  EKYCStatus.EXCEPTION,
  EKYCStatus.MANUAL_REVIEW,
  EKYCStatus.STEP_UP,
] as const;

export const EPublicKYCStatus = {
  APPROVED: "approved",
  DENIED: "denied",
  PENDING: "pending",
} as const;

export type TPublicKYCStatus =
  (typeof EPublicKYCStatus)[keyof typeof EPublicKYCStatus];

export const EPublicKYCStatusList = [
  EPublicKYCStatus.APPROVED,
  EPublicKYCStatus.DENIED,
  EPublicKYCStatus.PENDING,
] as const;

export const internalKYCStatusToPublicStatusMap: Record<
  EKYCStatus,
  TPublicKYCStatus
> = {
  [EKYCStatus.APPROVED]: EPublicKYCStatus.APPROVED,
  [EKYCStatus.DENIED]: EPublicKYCStatus.DENIED,
  [EKYCStatus.EXCEPTION]: EPublicKYCStatus.DENIED,
  [EKYCStatus.MANUAL_REVIEW]: EPublicKYCStatus.DENIED,
  [EKYCStatus.STEP_UP]: EPublicKYCStatus.DENIED,
};

export const isActionRequired = (
  status: EKYCStatus | TPublicKYCStatus | null | undefined
) =>
  status === EKYCStatus.DENIED ||
  status === EPublicKYCStatus.DENIED ||
  status === EKYCStatus.EXCEPTION ||
  status === EKYCStatus.MANUAL_REVIEW ||
  status === EKYCStatus.STEP_UP;

export const isApproved = (
  status: EKYCStatus | TPublicKYCStatus | null | undefined
) =>
  !status ||
  status === EKYCStatus.APPROVED ||
  status === EPublicKYCStatus.APPROVED;
