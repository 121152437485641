import { IResponseBase } from "./IResponseBase";

export const buildResponseBase = <T>(res: {
  status: number;
  data: T;
}): IResponseBase<T> => ({
  status: res.status,
  success: res.status === 200,
  data: res.data,
});
