export interface IWorkerBase {
  _id: string;
  first_name: string;
  middle_initial: string;
  last_name: string;
  taxengine_workerID: string;
  companyID: string;
  start_date: string;
  dob: string;
  ssn: string;
  email: string;
  address: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
  phone_number: string;
  workLocationID: string;
  is_943: boolean;
}

export interface ICompassLaForgeWorker extends IWorkerBase {
  taxengine_companyID: string;
  employee_check_dates: string[];
  tax_engine_check_ids: string[];
  default_pay_schedule: string;
  employee_id: string;
  county?: string | null;
  county_code?: string | null;
}

export interface IExtendedWorker extends IWorkerBase {
  employment_status: string;
  salary: number;
  salary_firstDate: string;
  default_pay_schedule: string;
  onboarded: boolean;
  term_date: string;
  term_reason: string;
  title: string;
  working_state: string;
  default_wage: number;
  is_salary: boolean;
  external_id: string;
}

export type LaForgeWorkersMapByCompanyID = Record<
  string,
  Record<string, ICompassLaForgeWorker>
>;

export enum worker_types {
  employee = "employee",
  contractor = "contractor",
}
