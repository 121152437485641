import { TApiPaginatedRequest } from "clients/AbstractApiClient/IApiRequest";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TApiPaginatedResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPayCardTransactionsResponseSchema } from "../../../OdoClient/schemas";
import { TGetPayCardTransactionsRequestSchema } from "./getPayCardTransactions.schema";

export const getPayCardTransactions = (axios: IAxiosInstance) => {
  return async (
    data: TApiPaginatedRequest<TGetPayCardTransactionsRequestSchema>
  ) => {
    const { pay_card_id, ...params } = data;

    const response = await axios.noRetry.get<
      TApiPaginatedResponse<TGetPayCardTransactionsResponseSchema>
    >(`/api/pay-cards/${pay_card_id}/transactions`, {
      params,
    });

    return response.data;
  };
};
