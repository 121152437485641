import type { ZealApiResponse, ZealApiSuccessResponse } from "@zeal/common";
import { ECompanyType, ECompanyStatus, EClassification } from "@zeal/common";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies";

type GetCompaniesResponseBody = {
  status: ECompanyStatus;
  is_sandbox: boolean;
  business_name: string;
  business_ein: string;
  classification: EClassification;
  company_type: ECompanyType;
  onboarding_url: string;
  companyID: string;
}[];

type GetCompaniesRequest = {
  partnerID?: string;
  companyID?: string;
  mode?: string;
};

type GetCompaniesResponse = ZealApiResponse<{ data: GetCompaniesResponseBody }>;

export const getCompanies =
  (axios: IAxiosInstance) =>
  async ({ partnerID, companyID, mode }: GetCompaniesRequest) => {
    try {
      const response = await axios.noRetry.get<GetCompaniesResponse>(PATH, {
        params: {
          partnerID,
          companyID,
          ...(mode === "test" ? { test: true } : {}),
        },
      });
      return response.data as ZealApiSuccessResponse<{
        data: GetCompaniesResponseBody;
      }>;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
