import type { ZealApiResponse } from "@zeal/common";
import type { ICreateCompanyBankAccountRequestBody } from "./ICreateCompanyBankAccountRequestBody.jsonschema";
import type { ICreateCompanyBankAccountResponseBody } from "./ICreateCompanyBankAccountResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies/bank";

type CreateCompanyBankAccountResponse =
  ZealApiResponse<ICreateCompanyBankAccountResponseBody>;

export const createCompanyBankAccount =
  (axios: IAxiosInstance) =>
  async (body: ICreateCompanyBankAccountRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<CreateCompanyBankAccountResponse>(PATH, body);
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
