import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateWorkerWalletRequestSchema,
  TCreateWorkerWalletResponseSchema,
} from "./createWorkerWallet.schema";

export const createWorkerWallet = (axios: IAxiosInstance) => {
  return async (data: TCreateWorkerWalletRequestSchema) => {
    const { worker_id, worker_type, ...rest } = data;

    const response = await axios.noRetry.post<
      IApiResponse<TCreateWorkerWalletResponseSchema>
    >(`/api/${worker_type}/${worker_id}/wallets`, rest);

    return response.data;
  };
};
