import { AbstractApiClient } from "../../clients/AbstractApiClient/AbstractApiClient";
import { CompanyApi } from "./CompanyApi/CompanyApi";
import { ContractorApi } from "./ContractorApi/ContractorApi";
import { EmployeeApi } from "./EmployeeApi/EmployeeApi";
import { TaxableLocationApi } from "./TaxableLocationApi/TaxableLocationApi";
import { WorkLocationApi } from "./WorkLocationApi/WorkLocationApi";

export class ZealApiClient extends AbstractApiClient {
  public readonly Company = CompanyApi(this.Instance);
  public readonly Contractor = ContractorApi(this.Instance);
  public readonly Employee = EmployeeApi(this.Instance);
  public readonly TaxableLocation = TaxableLocationApi(this.Instance);
  public readonly WorkLocation = WorkLocationApi(this.Instance);
}
