import type { ZealApiResponse } from "@zeal/common";
import type { IResolveTaxableLocationRequestBody } from "./IResolveTaxableLocationRequestBody.jsonschema";
import type { IResolveTaxableLocationResponseBody } from "./IResolveTaxableLocationResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/taxableLocations";

type ResolveTaxableLocationResponse =
  ZealApiResponse<IResolveTaxableLocationResponseBody>;

export const resolveTaxableLocation =
  (axios: IAxiosInstance) =>
  async (body: IResolveTaxableLocationRequestBody) => {
    try {
      const response = await axios.noRetry.post<ResolveTaxableLocationResponse>(
        PATH,
        body
      );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
