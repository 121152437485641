import {
  ECustomerAccountStatus,
  EOwnerType,
} from "../../../customer-accounts/ICustomerAccount";
import { LEGAL_STRUCTURES } from "../../../odo/IOdoCompany";
import { schemes, zSchema } from "../../../schema-validation";

export const postCustomerAccountRequestSchema = zSchema.object({
  business_name: zSchema.string(),
  code: zSchema.string(),
  company_id: zSchema.string(),
  partner_id: zSchema.string(),
  status: zSchema.nativeEnum(ECustomerAccountStatus).optional(),
  ein: zSchema.string().length(9, "EIN must be 9 characters").optional(),
  legal_structure: zSchema.enum(LEGAL_STRUCTURES).optional(),
  phone: zSchema.string().optional(),
  email: schemes.email().optional(),
  business_address: zSchema.string().optional(),
  business_city: zSchema.string().optional(),
  business_state: zSchema.string().optional(),
  business_zip: zSchema.string().optional(),
  business_owner: zSchema
    .object({
      first_name: zSchema.string().optional(),
      last_name: zSchema.string().optional(),
      email: schemes.email().optional(),
      ssn: zSchema.string().length(9, "SSN must be 9 characters").optional(),
      dob: zSchema.string().optional(),
      title: zSchema.string().optional(),
      ownership_percentage: zSchema.number().min(0).max(100).optional(),
      owner_type: zSchema.nativeEnum(EOwnerType).optional(),
      address: zSchema.string().optional(),
      address_line2: zSchema.string().optional().optional(),
      city: zSchema.string().optional(),
      state: zSchema.string().optional(),
      zip: zSchema.string().optional(),
    })
    .optional(),
});

export type TPostCustomerAccountRequestSchema = zSchema.infer<
  typeof postCustomerAccountRequestSchema
>;

export const postCustomerAccountResponseSchema = zSchema.object({
  business_name: zSchema.string(),
  code: zSchema.string(),
  company_id: zSchema.string(),
  partner_id: zSchema.string(),
  customer_account_id: zSchema.string(),
  status: zSchema.nativeEnum(ECustomerAccountStatus).optional(),
  ein: zSchema.string().length(9, "EIN must be 9 characters").optional(),
  legal_structure: zSchema.enum(LEGAL_STRUCTURES).optional(),
  phone: zSchema.string().optional(),
  email: schemes.email().optional(),
  business_address: zSchema.string().optional(),
  business_city: zSchema.string().optional(),
  business_state: zSchema.string().optional(),
  business_zip: zSchema.string().optional(),
  business_owner: zSchema
    .object({
      first_name: zSchema.string().optional(),
      last_name: zSchema.string().optional(),
      email: schemes.email().optional(),
      ssn: zSchema.string().length(9, "SSN must be 9 characters").optional(),
      dob: zSchema.string().optional(),
      title: zSchema.string().optional(),
      ownership_percentage: zSchema.number().min(0).max(100).optional(),
      owner_type: zSchema.nativeEnum(EOwnerType).optional(),
      address: zSchema.string().optional(),
      address_line2: zSchema.string().optional().optional(),
      city: zSchema.string().optional(),
      state: zSchema.string().optional(),
      zip: zSchema.string().optional(),
    })
    .optional(),
  onboarded: zSchema.boolean().optional(),
});

export type TPostCustomerAccountResponseSchema = zSchema.infer<
  typeof postCustomerAccountResponseSchema
>;
