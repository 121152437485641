import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreatePushToCardBusinessAccountRequestSchema,
  TCreatePushToCardBusinessAccountResponseSchema,
} from "./createPushToCardBusinessAccount.schema";

export const createPushToCardBusinessAccount = (axios: IAxiosInstance) => {
  return async (body: TCreatePushToCardBusinessAccountRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreatePushToCardBusinessAccountResponseSchema>
    >(`/api/push-to-card/business/accounts`, body);

    return response.data;
  };
};
