import { EPaySchedule } from "../../employees";
import { zSchema } from "../../schema-validation";
import { ETxnSpeed } from "../../transaction-speeds";
import { EDisbursementMethod } from "../../workers-shared";
import { PayrollRunStatus } from "../PayrollRunStatus";
import { PayrollRunType } from "../PayrollRunType";

export const payrollRunSchema = zSchema.object({
  _id: zSchema.string(),
  checks: zSchema.array(zSchema.string()),
  companyID: zSchema.string(),
  cutOffDate: zSchema.string(),
  payDate: zSchema.string(),
  payFrequency: zSchema.nativeEnum(EPaySchedule),
  payPeriod: zSchema.object({
    start: zSchema.string(),
    end: zSchema.string(),
    reportingPeriodID: zSchema.string(),
  }),
  speed: zSchema.nativeEnum(ETxnSpeed),
  status: zSchema.nativeEnum(PayrollRunStatus),
  type: zSchema.nativeEnum(PayrollRunType),
  disbursementMethod: zSchema.nativeEnum(EDisbursementMethod).optional(),
});

export type TPayrollRun = zSchema.infer<typeof payrollRunSchema>;
