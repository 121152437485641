import { zSchema, schemes, ETransactionStatus } from "@zeal/common";

export const getPendingTopUpsRequestSchema = zSchema
  .object({
    odo_user_ids: zSchema.array(schemes.mongoOrUuid()).nonempty(),
  })
  .strict();

export type TGetPendingTopUpsRequestSchema = zSchema.TypeOf<
  typeof getPendingTopUpsRequestSchema
>;

export type TGetPendingTopUpsResponseSchema = {
  readonly odo_user_id: string;
  readonly status: ETransactionStatus;
  readonly top_up_id: string;
}[];
