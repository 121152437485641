import { EDisbursementMethod, schemes, zSchema } from "@zeal/common";

export const getPayrollRunEmployeesRequestSchema = zSchema.object({
  disbursement_method: zSchema.nativeEnum(EDisbursementMethod).optional(),
  // pay_schedule: zSchema.nativeEnum(EPaySchedule).optional(),
  pay_schedule: zSchema.string().optional(),
  payroll_run_id: schemes.mongoObjectId(),
  regular_only: zSchema.boolean().optional(),
});

export type TGetPayrollRunEmployeesRequest = zSchema.infer<
  typeof getPayrollRunEmployeesRequestSchema
>;
