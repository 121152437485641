import { AxiosResponse } from "axios";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import { buildResponseBase, IAmountPaidSummary } from "@zeal/common";
export const updatePaymentSubmission =
  (axios: IAxiosInstance) => async (obj: IAmountPaidSummary[]) => {
    const url = "/private/api/compass/updatePaymentSubmission";

    const { data } = await axios.noRetry.request<
      any,
      AxiosResponse<any>,
      IAmountPaidSummary[]
    >({
      method: "PUT",
      url,
      data: obj,
    });

    return buildResponseBase({ status: data.status, data: data.data });
  };
