import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const onboardCustomerAccount = (axios: IAxiosInstance) => {
  return async (params: { companyID: string; customerAccountID: string }) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      `/api/customer-accounts/${params.customerAccountID}/setOnboardedStatusToTrue`,
      {
        companyID: params.companyID,
      }
    );

    return response.data;
  };
};
