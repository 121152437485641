import { IGeneratedFiles, IGetJobStatusRequest } from "@zeal/common";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../AbstractApiClient/IApiResponse";

export const checkJobStatus =
  (axios: IAxiosInstance) => async (obj: IGetJobStatusRequest) => {
    const res = await axios.noRetry.post<IApiResponse<IGeneratedFiles>>(
      "/private/api/compass/checkJobStatus",
      obj
    );

    return res.data;
  };
