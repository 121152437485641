export enum TestMode {
  Test = "test",
  Live = "live",
}
export type UserTypes =
  | "contractor"
  | "guest"
  | "puzzl_internal"
  | "puzzl_internal_guest"
  | "sole_co"
  | "white_label"
  | "wladmin"
  | "worker";

export interface ISiskoDefaultPageProps {
  readonly companyID: string;
  readonly isTestMode: boolean;
  readonly mode: TestMode;
  readonly userType: UserTypes;
}

export enum UserType {
  Contractor = "contractor",
  Employee = "Worker",
  Partner = "WhiteLabel",
  PartnerAdmin = "WhiteLabelAdmin",
  Company = "User",
  internationalContractor = "internationalContractor",
}

export type PayrollRunTypes = "regular" | "offCycle";
export type PayrollRunStatus =
  | "pending"
  | "submitted"
  | "incomplete"
  | "processed";

export enum EmploymentStatus {
  Live = "live",
  Leave = "leave",
  Terminated = "terminated",
}
