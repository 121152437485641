import { schemes, zSchema } from "../../schema-validation";
import { WalletStatus } from "../WalletStatus";

export const odoWalletSchema = zSchema.object({
  available_balance: zSchema.number(),
  current_balance: zSchema.number(),
  odo_user_id: schemes.mongoOrUuid(),
  status: zSchema.nativeEnum(WalletStatus),
  wallet_id: schemes.uuid(),
});
