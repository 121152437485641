import { contractorSchema, schemes, zSchema } from "@zeal/common";

export const getContractorsResponseSchema = zSchema.array(
  contractorSchema.extend({
    bankAccountID: schemes.mongoOrUuid().optional(),
  })
);

export type TGetContractorsResponseSchema = zSchema.infer<
  typeof getContractorsResponseSchema
>;
