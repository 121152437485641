import { schemes, zSchema } from "@zeal/common";

export const createPushToCardWorkerRequestSchema = zSchema.object({
  email: zSchema.string().email(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  odo_user_id: schemes.mongoOrUuid(),
  phone: zSchema.string(),
  address_1: zSchema.string(),
  address_2: zSchema.string().optional(),
  city: zSchema.string(),
  state: zSchema.string(),
  postal_code: zSchema.string(),
  date_of_birth: zSchema.string(),
  worker_id: zSchema.string(),
  ip_address: zSchema.string(),
});

export type TCreatePushToCardWorkerRequestSchema = zSchema.infer<
  typeof createPushToCardWorkerRequestSchema
>;

export const createPushToCardWorkerResponseSchema = zSchema.object({
  odo_user_id: zSchema.string(),
});

export type TCreatePushToCardWorkerResponseSchema = zSchema.infer<
  typeof createPushToCardWorkerResponseSchema
>;
