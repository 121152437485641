import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPendingTopUpsRequestSchema,
  TGetPendingTopUpsResponseSchema,
} from "./getPendingTopUps.schema";

export const getPendingTopUps =
  (axios: IAxiosInstance) => async (params: TGetPendingTopUpsRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetPendingTopUpsResponseSchema>
    >(`/api/pending-top-ups`, { params });
    return response.data;
  };
