import type { ZealApiErrorResponse } from "@zeal/common";
import type { IGenContractorOnboardingLinkRequestBody } from "./IGenContractorOnboardingLinkRequestBody.jsonschema";
import type { IGenContractorOnboardingLinkResponseBody } from "./IGenContractorOnboardingLinkResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";
import { IIDScanDocuments } from "../../../LaForgeClient/routes/getOnboardingAuthLink/getOnboardingAuthLink.jsonschema";

const PATH = "/contractors/onboard";

type GenContractorOnboardingLinkResponse =
  | {
      readonly success: true;
      readonly data: IGenContractorOnboardingLinkResponseBody;
      readonly accountStageDisabled: boolean;
      readonly stageFlags: {
        readonly account: boolean;
        readonly i9: boolean;
        readonly idScan: boolean;
        readonly paymentMethod: boolean;
      };
      readonly idScanDocuments?: IIDScanDocuments;
    }
  | ZealApiErrorResponse;

export const genContractorOnboardingLink =
  (axios: IAxiosInstance) =>
  async (body: IGenContractorOnboardingLinkRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<GenContractorOnboardingLinkResponse>(
          PATH,
          body
        );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
