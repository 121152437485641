import {
  zSchema,
  schemes,
  ProviderAccountPurpose,
  ProviderAccountStatus,
  TPublicPaginationSchema,
} from "@zeal/common";

export const listAccountBalancesRequest = zSchema
  .object({
    odo_user_ids: zSchema.array(schemes.mongoOrUuid()).default([]),
    account_purposes: zSchema
      .array(zSchema.nativeEnum(ProviderAccountPurpose))
      .default([]),
  })
  .strict();

export type TListAccountBalancesRequestSchema = zSchema.infer<
  typeof listAccountBalancesRequest
> &
  TPublicPaginationSchema;

export type TListAccountBalancesResponse = {
  account_purpose: ProviderAccountPurpose;
  available_balance: number;
  current_balance: number;
  odo_user_id: string;
  provider_account_id: string;
  status: ProviderAccountStatus | null;
}[];
