export enum InputTypeEnum {
  Checkbox = "checkbox",
  Date = "date",
  Input = "input",
  RadioGroup = "radio-group",
  Select = "select",
  SearchableSelect = "searchable-select",
  Switch = "switch",
  MaskedInput = "masked-input",
}

export interface IControlledInputField {
  componentProps: {
    defaultValue?: string;
    inputProps?: {
      label: string;
      onKeyDown?: (e: KeyboardEvent) => void;
      placeholder?: string;
    };
    label?: string;
    maskType?:
      | "ssn"
      | "phone-number"
      | "ein"
      | "acct"
      | "routing"
      | "password"
      | "number";
    menuItems?: { label: string; value: string }[];
    placeholder?: string;
    radioItems?: { label: string; value: string }[];
    required?: boolean;
  };
  /**
   * Used to hide/show a field based on the value of another field
   */
  controlledBy?: string;
  controls?: string[];
  inputType: InputTypeEnum;
  isReadOnly?: boolean;
  propertyId: string;
  tooltipText?: string; // Renders a tooltip next to the field label
}

export const EFieldName = {
  first_name: "first_name",
  middle_initial: "middle_initial",
  last_name: "last_name",
  dob: "dob",
  ssn: "ssn",
  phone_number: "phone_number",
  address: "address",
  address_line2: "address_line2",
  city: "city",
  state: "state",
  zip: "zip",
} as const;

export type TFieldName = (typeof EFieldName)[keyof typeof EFieldName];

export type TAllFields = Partial<Record<TFieldName, IControlledInputField>>;

/**
 * Instructions for adding new fields:
 * 1. propertyId must match the field's name in the Worker document
 * 2. Add the new field's zod schema to ./zodSchemas.ts
 */
export const allFields: TAllFields = {
  [EFieldName.first_name]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.first_name,
    componentProps: {
      label: "First name",
      placeholder: "Chester",
    },
  },
  [EFieldName.middle_initial]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.middle_initial,
    componentProps: {
      label: "Middle initial",
      placeholder: "M",
    },
  },
  [EFieldName.last_name]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.last_name,
    componentProps: {
      label: "Last name",
      placeholder: "Cheeto",
    },
  },
  [EFieldName.dob]: {
    inputType: InputTypeEnum.Date,
    propertyId: EFieldName.dob,
    componentProps: {
      label: "Date of birth",
      placeholder: "Select date",
    },
  },
  [EFieldName.ssn]: {
    inputType: InputTypeEnum.MaskedInput,
    propertyId: EFieldName.ssn,
    componentProps: {
      inputProps: {
        label: "Social Security number",
      },
      maskType: "ssn",
    },
  },
  [EFieldName.phone_number]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.phone_number,
    componentProps: {
      label: "Phone number",
      placeholder: "(123)456-7890",
    },
  },
  [EFieldName.address]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.address,
    componentProps: {
      label: "Address",
      placeholder: "123 Main St",
    },
  },
  [EFieldName.address_line2]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.address_line2,
    componentProps: {
      label: "Address line 2",
      placeholder: "Apt 456",
    },
  },
  [EFieldName.city]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.city,
    componentProps: {
      label: "City",
      placeholder: "City",
    },
  },
  [EFieldName.state]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.state,
    componentProps: {
      label: "State",
      placeholder: "CA",
    },
  },
  [EFieldName.zip]: {
    inputType: InputTypeEnum.Input,
    propertyId: EFieldName.zip,
    componentProps: {
      label: "Zip",
      placeholder: "12345",
    },
  },
};
