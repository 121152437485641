import type { ZealApiResponse } from "@zeal/common";
import type { ISetContractorOnboardStatusRequestBody } from "./ISetContractorOnboardStatusRequestBody.jsonschema";
import type { ISetContractorOnboardStatusResponseBody } from "./ISetContractorOnboardStatusResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/contractors/setOnboardedStatusToTrue";

type SetContractorOnboardStatusResponse =
  ZealApiResponse<ISetContractorOnboardStatusResponseBody>;

export const setContractorOnboardStatus =
  (axios: IAxiosInstance) =>
  async (body: ISetContractorOnboardStatusRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<SetContractorOnboardStatusResponse>(
          PATH,
          body
        );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
