import type { ZealApiResponse } from "@zeal/common";
import type { IGetCompanyAuthDocsRequestParams } from "./IGetCompanyAuthDocsRequestParams.jsonschema";
import type { GetCompanyAuthDocsResponseBody } from "./GetCompanyAuthDocsResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies/authorization_documents";

type GetCompanyAuthDocsResponse =
  ZealApiResponse<GetCompanyAuthDocsResponseBody>;

export const getCompanyAuthDocs =
  (axios: IAxiosInstance) =>
  async (params: IGetCompanyAuthDocsRequestParams) => {
    try {
      const response = await axios.noRetry.get<GetCompanyAuthDocsResponse>(
        PATH,
        { params }
      );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
