/**
 * This file contains all type definitions and helper configurations
 * used by the Custom Reports module. It defines:
 *
 * • Allowed field definitions for each collection.
 * • Utility types for dot-notation, filter construction, and projection requests.
 * • An aggregated mapping (CustomReportCollections) for lookup convenience.
 */

import mongoose from "mongoose";
import {
  TSingleFilter,
  TMultipleFilter,
  TExcludeFilter,
  TRegexFilter,
  TIncludeFilter,
  TEqualsFilter,
  TGreaterThanOrEqualFilter,
  TLessThanOrEqualFilter,
  TGreaterThanFilter,
  TLessThanFilter,
} from "../bolarus";

// Import collection interfaces.
import { IExtendedWorker } from "../workers-shared";
import { IEmployeeCheck } from "../employee-checks";
import { IBank } from "../accounts";
import { ILaForgeCompany } from "../companies";
import { IShift } from "../shifts";
import { TLaForgeContractor } from "../contractors";
import { IContractorPayment } from "../contractor-payments";

/*-----------------------------------------------------------------------------
  Basic Helpers and Type Utilities
-----------------------------------------------------------------------------*/

/**
 * AllowedFields is simply a read-only array of allowed keys for a given type.
 */
export type AllowedFields<T> = readonly (keyof T)[];

/**
 * Primitive types for our purposes.
 */
type Primitive = string | number | boolean | Date | mongoose.Types.ObjectId;

/**
 * DotNotation recursively constructs a dot-notated key for nested objects.
 * If T is a primitive or an array, it stops recursing.
 */
type DotNotation<T> = T extends Primitive
  ? ""
  : T extends Array<any>
  ? ""
  : {
      [K in keyof T & string]: K | `${K}.${DotNotation<T[K]>}`;
    }[keyof T & string];

/**
 * SearchableFields designates high-level areas where search is enabled.
 */
export type SearchableFields =
  | "worker"
  | "check"
  | "company"
  | "shift"
  | "bank_account"
  | "customer_account"
  | "work_location";

/**
 * TAllowedFieldsConfig defines the configuration for each allowed field.
 *
 * @property type - The primitive type, or special types (such as "id_search" or "option").
 * @property format - (Optional) Formatting string for dates or other formatted fields.
 * @property options - (For "option" types) a list of acceptable values.
 * @property label - A human-readable name.
 * @property filterable - If true, the field may be used in filtering conditions.
 * @property internal - If true, the field is used only internally (e.g., for lookups) and not exposed in end reports.
 * @property searchConfig - Provides extra search options, indicating which area is searchable.
 */
export type TAllowedFieldsConfig = {
  type:
    | "string"
    | "number"
    | "boolean"
    | "date"
    | "option"
    | "object"
    | "id_search";
  format?: string;
  options?: readonly string[];
  label?: string;
  filterable: boolean;
  internal?: boolean;
  searchConfig?: {
    area: SearchableFields;
  };
};

/**
 * AllowedFields2 maps dot-notated keys for type T to their corresponding field configuration.
 */
export type AllowedFields2<T> = {
  [P in DotNotation<T>]?: TAllowedFieldsConfig;
};

/*-----------------------------------------------------------------------------
  Collection Field Definitions
-----------------------------------------------------------------------------*/

/**
 * Work location fields.
 */
export const AllowedWorkLocationFields = [
  "locationID",
  "work_site_id",
  "name",
] as const;

/**
 * Generic address fields used across several collections.
 */
export const AllowedLocationFields = [
  "address",
  "address_line2",
  "city",
  "state",
  "zip",
] as const;

/**
 * AllowedWorkerFields2 defines fields available from the workers collection.
 * Note that many fields are marked as internal because they are used for lookups (e.g., companyID, workLocationID).
 */
export const AllowedWorkerFields2: AllowedFields2<IExtendedWorker> = {
  _id: { type: "string", filterable: true, internal: true },
  email: { type: "string", filterable: true, label: "Email" },
  first_name: { type: "string", filterable: true, label: "First Name" },
  last_name: { type: "string", filterable: true, label: "Last Name" },
  middle_initial: { type: "string", filterable: true, label: "Middle Initial" },
  phone_number: { type: "string", filterable: true, label: "Phone Number" },
  companyID: { type: "string", filterable: true, internal: true },
  workLocationID: {
    type: "id_search",
    filterable: true,
    internal: true,
    label: "Work Location ID",
    searchConfig: { area: "work_location" },
  },
  employment_status: {
    type: "option",
    filterable: true,
    options: ["leave", "live", "terminated"],
    label: "Employment Status",
  },
  salary: { type: "number", filterable: true, label: "Salary" },
  salary_firstDate: {
    type: "date",
    format: "YYYY-MM-DD",
    filterable: true,
    label: "Salary First Date",
  },
  onboarded: { type: "boolean", filterable: true, label: "Onboarded" },
  dob: { type: "string", filterable: true, label: "Date of Birth" },
  ssn: { type: "string", filterable: true, label: "SSN" },
  address: { type: "string", filterable: true, label: "Address" },
  city: { type: "string", filterable: true, label: "City" },
  state: { type: "string", filterable: true, label: "State" },
  zip: { type: "string", filterable: true, label: "Zip" },
  start_date: { type: "date", filterable: true, label: "Start Date" },
  term_date: { type: "date", filterable: true, label: "Term Date" },
  term_reason: { type: "string", filterable: true, label: "Term Reason" },
  taxengine_workerID: {
    type: "string",
    filterable: true,
    internal: true,
    label: "TaxEngine Worker ID",
  },
  title: { type: "string", filterable: true, label: "Title" },
  working_state: { type: "string", filterable: true, label: "Working State" },
  address_line2: { type: "string", filterable: true, label: "Address Line 2" },
  default_pay_schedule: {
    type: "string",
    filterable: true,
    label: "Default Pay Schedule",
  },
  default_wage: { type: "number", filterable: true, label: "Default Wage" },
  is_salary: { type: "boolean", filterable: true, label: "Is Salary" },
  external_id: { type: "string", filterable: true, label: "External ID" },
} as const;

/**
 * AllowedCheckFields defines fields for employee checks.
 */
export const AllowedCheckFields: AllowedFields2<IEmployeeCheck> = {
  paystub_id: { type: "string", filterable: true, internal: true },
  employeeID: { type: "string", filterable: true, internal: true },
  companyID: { type: "string", filterable: true, internal: true },
  check_date: {
    type: "date",
    format: "YYYY-MM-DD",
    label: "Check Date",
    filterable: true,
  },
  status: { type: "string", filterable: true, label: "Status" },
  net_pay: { type: "number", filterable: true, label: "Net Pay" },
  "disbursement.method": {
    type: "option",
    options: [
      "direct_deposit",
      "download_check",
      "prepaid",
      "mail_check",
      "paycard",
    ],
    filterable: true,
    label: "Disbursement Method",
  },
  "disbursement.status": {
    type: "string",
    filterable: true,
    label: "Disbursement Status",
  },
  "totals.gross_pay": { type: "number", filterable: true, label: "Gross Pay" },
  "totals.net_pay": { type: "number", filterable: true, label: "Net Pay" },
  "totals.gross_earnings": {
    type: "number",
    filterable: true,
    label: "Gross Earnings",
  },
  "totals.employee_taxes": {
    type: "number",
    filterable: true,
    label: "Employee Taxes",
  },
  "totals.employer_taxes": {
    type: "number",
    filterable: true,
    label: "Employer Taxes",
  },
  "totals.employee_deductions": {
    type: "number",
    filterable: true,
    label: "Employee Deductions",
  },
  "totals.employer_deductions": {
    type: "number",
    filterable: true,
    label: "Employer Deductions",
  },
  "totals.employee_garnishments": {
    type: "number",
    filterable: true,
    label: "Employee Garnishments",
  },
  first_name: { type: "string", filterable: true, label: "First Name" },
  last_name: { type: "string", filterable: true, label: "Last Name" },
  middle_initial: { type: "string", filterable: true, label: "Middle Initial" },
  is_salary: { type: "boolean", filterable: true, label: "Is Salary" },
  approval_required: {
    type: "boolean",
    filterable: true,
    label: "Approval Required",
  },
  approved: { type: "boolean", filterable: true, label: "Approved" },
  taxengine_check_id: { type: "string", filterable: true, internal: true },
  //   ocw_checkId: { type: "string", filterable: true, internal: true },
  //   checkbook_checkId: { type: "string", filterable: true, internal: true },
} as const;

/**
 * AllowedBankAccountFields defines allowed fields for bank account reports.
 */
export const AllowedBankAccountFields: AllowedFields2<IBank> = {
  employeeID: { type: "string", filterable: true, internal: true },
  institution_name: {
    type: "string",
    filterable: true,
    label: "Institution Name",
  },
  routing_number: { type: "string", filterable: true, label: "Routing Number" },
  account_number: { type: "string", filterable: true, label: "Account Number" },
} as const;

/**
 * AllowedCompanyFields defines allowed fields for company reports.
 * Fields marked as internal (e.g., company_id) are only for joins and lookups.
 */
export const AllowedCompanyFields: AllowedFields2<ILaForgeCompany> = {
  company_id: { type: "string", filterable: true, internal: true },
  business_name: { type: "string", filterable: true, label: "Business Name" },
  business_ein: { type: "string", filterable: true, label: "Business EIN" },
  status: { type: "string", filterable: true, label: "Status" },
};

/**
 * AllowedShiftFields defines allowed fields for shifts.
 */
export const AllowedShiftFields: AllowedFields2<IShift> = {
  shiftID: {
    type: "id_search",
    filterable: true,
    label: "Shift ID",
    internal: true,
  },
  employeeID: {
    type: "string",
    filterable: true,
    label: "Employee ID",
    internal: true,
  },
  companyID: {
    type: "string",
    filterable: true,
    label: "Company ID",
    internal: true,
  },
  employeeCheckID: {
    type: "id_search",
    filterable: true,
    label: "Employee Check ID",
    internal: true,
  },
  workLocationID: {
    type: "id_search",
    filterable: true,
    label: "Work Location ID",
    searchConfig: { area: "work_location" },
  },
  customerAccountID: {
    type: "id_search",
    filterable: true,
    label: "Customer Account ID",
    searchConfig: { area: "customer_account" },
  },
  status: { type: "string", filterable: true, label: "Status" },
  time: { type: "string", filterable: true, label: "Time" },
  first_name: { type: "string", filterable: true, label: "First Name" },
  last_name: { type: "string", filterable: true, label: "Last Name" },
  middle_initial: { type: "string", filterable: true, label: "Middle Initial" },
  "hourly.hours": { type: "number", filterable: true, label: "Hourly Hours" },
  "hourly.wage": { type: "number", filterable: true, label: "Hourly Wage" },
  "overtime.hours": {
    type: "number",
    filterable: true,
    label: "Overtime Hours",
  },
  "overtime.wage": { type: "number", filterable: true, label: "Overtime Wage" },
  "doubletime.hours": {
    type: "number",
    filterable: true,
    label: "Doubletime Hours",
  },
  "doubletime.wage": {
    type: "number",
    filterable: true,
    label: "Doubletime Wage",
  },
  mark_for_review: {
    type: "boolean",
    filterable: true,
    label: "Mark for Review",
  },
};

/**
 * AllowedContractorFields defines allowed fields for contractor reports.
 */
export const AllowedContractorFields: AllowedFields2<TLaForgeContractor> = {
  _id: { type: "string", filterable: true, internal: true },
  business_name: { type: "string", filterable: true, label: "Business Name" },
  ein: { type: "id_search", filterable: true, label: "EIN" },
  companyID: {
    type: "string",
    filterable: true,
    internal: true,
    searchConfig: { area: "company" },
  },
  address: { type: "string", filterable: true, label: "Address" },
  city: { type: "string", filterable: true, label: "City" },
  state: { type: "string", filterable: true, label: "State" },
  zip: { type: "string", filterable: true, label: "Zip" },
  first_name: { type: "string", filterable: true, label: "First Name" },
  last_name: { type: "string", filterable: true, label: "Last Name" },
  email: { type: "string", filterable: true, label: "Email" },
  onboarded: { type: "boolean", filterable: true, label: "Onboarded" },
  onboardedAt: { type: "date", filterable: true, label: "Onboarded At" },
  organization: { type: "string", filterable: true, label: "Organization" },
  type: { type: "option", filterable: true, label: "Type" },
  working_state: { type: "string", filterable: true, label: "Working State" },
  dob: { type: "date", filterable: true, label: "Date of Birth" },
  ssn: { type: "string", filterable: true, label: "SSN" },
  address_line2: { type: "string", filterable: true, label: "Address Line 2" },
  onboard_docs_faxed: {
    type: "boolean",
    filterable: true,
    label: "Onboard Docs Faxed",
  },
  employment_status: {
    type: "option",
    filterable: true,
    label: "Employment Status",
    options: ["live", "terminated"],
  },
} as const;

/**
 * AllowedContractorPaymentFields defines allowed fields for contractor payments.
 */
export const AllowedContractorPaymentFields: AllowedFields2<IContractorPayment> =
  {
    contractorID: { type: "string", filterable: true, internal: true },
    amount: { type: "number", filterable: true, label: "Amount" },
    pay_date: { type: "date", filterable: true, label: "Payment Date" },
    companyID: {
      type: "id_search",
      filterable: true,
      internal: true,
      searchConfig: { area: "company" },
    },
    status: {
      type: "option",
      filterable: true,
      label: "Status",
      options: ["pending", "paid", "failed"],
    },
    approved: { type: "boolean", filterable: true, label: "Approved" },
    approval_required: {
      type: "boolean",
      filterable: true,
      label: "Approval Required",
    },
    first_name: { type: "string", filterable: true, label: "First Name" },
    last_name: { type: "string", filterable: true, label: "Last Name" },
    group_id: { type: "string", filterable: true, label: "Group ID" },
    speed: { type: "string", filterable: true, label: "Speed" },
  } as const;

/*-----------------------------------------------------------------------------
  Utility and Combined Types for Custom Reports
-----------------------------------------------------------------------------*/

/**
 * Define union types based on the allowed keys for each collection.
 */
export type WorkerFields = keyof typeof AllowedWorkerFields2;
export type CheckFields = keyof typeof AllowedCheckFields;
export type ContractorPaymentFields =
  keyof typeof AllowedContractorPaymentFields;
export type CompanyFields = keyof typeof AllowedCompanyFields;
export type ShiftFields = keyof typeof AllowedShiftFields;

/**
 * CustomReportProjections is a union of keys from all allowed field sets.
 */
export type CustomReportProjections =
  | keyof typeof AllowedWorkerFields2
  | keyof typeof AllowedCheckFields
  | keyof typeof AllowedCompanyFields
  | keyof typeof AllowedShiftFields
  | keyof typeof AllowedContractorFields
  | keyof typeof AllowedContractorPaymentFields;

/**
 * ProjectionsByCollection defines the shape of a projection request.
 * For each collection, a client provides an object marking which fields should be included.
 */
export type ProjectionsByCollection = {
  worker?: {
    [K in keyof typeof AllowedWorkerFields2]?: 1;
  };
  check?: {
    [K in keyof typeof AllowedCheckFields]?: 1;
  };
  company?: {
    [K in keyof typeof AllowedCompanyFields]?: 1;
  };
  shift?: {
    [K in keyof typeof AllowedShiftFields]?: 1;
  };
  bank_account?: {
    [K in keyof typeof AllowedBankAccountFields]?: 1;
  };
  contractor?: {
    [K in keyof typeof AllowedContractorFields]?: 1;
  };
  contractor_payment?: {
    [K in keyof typeof AllowedContractorPaymentFields]?: 1;
  };
};

/**
 * Filter utilities for each field type.
 */
export type StringFieldFilters<K extends string> =
  | TSingleFilter<string, K>
  | TMultipleFilter<string[], K>
  | TExcludeFilter<string[], K>
  | TRegexFilter<string, K>
  | TIncludeFilter<string, K>;

export type NumberFieldFilters<K extends string> =
  | TEqualsFilter<number, K>
  | TGreaterThanOrEqualFilter<number, K>
  | TLessThanOrEqualFilter<number, K>
  | TGreaterThanFilter<number, K>
  | TLessThanFilter<number, K>;

export type BooleanFieldFilters<K extends string> = TEqualsFilter<boolean, K>;

/**
 * FilterForField uses the field configuration (type and filterability)
 * to determine the correct filter type.
 */
export type FilterForField<T, K extends string> = T extends {
  type: "string";
  filterable: true;
}
  ? StringFieldFilters<K>
  : T extends { type: "number"; filterable: true }
  ? NumberFieldFilters<K>
  : T extends { type: "boolean"; filterable: true }
  ? BooleanFieldFilters<K>
  : any;

/**
 * CustomReportFilters maps each allowed field (for a collection) to its proper filter type.
 */
export type CustomReportFilters<T> = {
  [K in keyof T]: FilterForField<T[K], K & string>;
}[keyof T];

/**
 * CustomReportFiltersCombined is a helper type for multiple filters.
 */
export type CustomReportFiltersCombined = CustomReportFilters<
  typeof AllowedWorkerFields2
>[];

/**
 * CustomReportFiltersRequest defines the filter request structure.
 * It maps each possible projection key (from any collection) to its filter definition.
 */
export type CustomReportFiltersRequest = {
  [K in CustomReportProjections]?: FilterForField<
    (typeof AllowedWorkerFields2 &
      typeof AllowedCheckFields &
      typeof AllowedCompanyFields &
      typeof AllowedShiftFields &
      typeof AllowedContractorFields &
      typeof AllowedContractorPaymentFields)[K],
    K
  >;
};

/**
 * CustomReportCollections aggregates allowed field configurations
 * by collection name. This is useful for generating reports and lookups.
 */
export const CustomReportCollections = {
  worker: AllowedWorkerFields2,
  check: AllowedCheckFields,
  company: AllowedCompanyFields,
  shift: AllowedShiftFields,
  bank_account: AllowedBankAccountFields,
  contractor: AllowedContractorFields,
  contractorpayment: AllowedContractorPaymentFields,
} as const;

/**
 * CustomReportRequest defines the overall structure of a custom report query.
 * It includes projections, filters, optional sort, and groupBy specifications.
 */
export type CustomReportRequest = {
  projections: ProjectionsByCollection;
  filters: CustomReportFiltersRequest;
  sort?: any;
  groupBy?: any;
};
