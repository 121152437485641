import dayjs from "dayjs";
import { z } from "zod";

export const assertValidDate = (
  date: string,
  ctx: z.RefinementCtx,
  msg: string
) => {
  if (!dayjs(date).isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: msg,
      path: ctx.path,
    });
    return z.NEVER;
  }

  return z.OK;
};
