import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPayCardTransactionResponseSchema } from "../../../OdoClient/schemas";
import { TGetPayCardTransactionRequestSchema } from "./getPaycardTransaction.schema";

export const getPaycardTransaction = (axios: IAxiosInstance) => {
  return async (data: TGetPayCardTransactionRequestSchema) => {
    const { paycard_id, transaction_id, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardTransactionResponseSchema>
    >(`/api/pay-cards/${paycard_id}/transactions/${transaction_id}`, {
      params: rest,
    });

    return response.data;
  };
};
