import { ProviderAccountPurpose, schemes, zSchema } from "@zeal/common";

export const createPushToCardBusinessAccountRequestSchema = zSchema.object({
  account_type: zSchema.nativeEnum(ProviderAccountPurpose),
  odo_user_id: schemes.mongoOrUuid(),
});

export type TCreatePushToCardBusinessAccountRequestSchema = zSchema.infer<
  typeof createPushToCardBusinessAccountRequestSchema
>;

export const createPushToCardBusinessAccountResponseSchema = zSchema.object({
  odo_user_id: zSchema.string(),
});

export type TCreatePushToCardBusinessAccountResponseSchema = zSchema.infer<
  typeof createPushToCardBusinessAccountResponseSchema
>;
