import { z } from "zod";
import { schemes } from "../../schema-validation";

export const laForgeWorkLocationSchema = z.object({
  companyID: schemes.uuid(),
  workLocationId: schemes.uuid(),
  quarkWorkLocationId: schemes.mongoObjectId(),
  quarkTaxableLocationId: schemes.mongoObjectId(),
  street1: z.string(),
  street2: z.string().optional(),
  city: z.string(),
  state: schemes.jurisdiction(),
  zip: schemes.zipCode(),
  workSiteId: z.string().optional(),
  name: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
});

export type LaForgeWorkLocation = z.infer<typeof laForgeWorkLocationSchema>;
