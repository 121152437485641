export const toDollars = (input: string | number | null | undefined) =>
  (Number(input) / 100).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
export const toDecimal = (input: string | number | null | undefined) =>
  (Number(input) / 100).toFixed(2);
export const toCents = (input: string | number | null | undefined) =>
  Number(input) * 100;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (num: any) =>
  !Number.isNaN(parseFloat(num)) && Number.isFinite(num);

export const getUSDollarFormat = (number?: number) =>
  number
    ? number.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    : `$0.00`;

// TODO: Britt Danzer - June 1st 2023 - Need to clean this function up and toDollars above
export const getUSDollarFormatToDollars = (amount?: number | string) =>
  amount || amount === 0
    ? (Number(amount) / 100).toLocaleString("en", {
        style: "currency",
        currency: "USD",
      })
    : `$0.00`;

export const getCentToDollarWSpace = (
  amount?: string | number | null | undefined
) =>
  amount
    ? `$ ${getUSDollarFormatToDollars(amount)?.replace(/^./, "")}`
    : `$ 0.00`;

export const getDollarsWSpace = (
  amount?: string | number | null | undefined
) =>
  amount
    ? `${Number(amount) < 0 ? "-" : ""}$ ${getUSDollarFormat(
        Math.abs(Number(amount))
      )?.replace(/^./, "")}`
    : "$ 0.00";
