import type { ZealApiResponse } from "@zeal/common";
import type { IGetCompanyOnboardingLinkRequestParams } from "./IGetCompanyOnboardingLinkRequestParams.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies/onboard";

// This is a legacy route not following the new fed-sdk standards
type GetCompanyOnboardingLinkResponse = ZealApiResponse<string>;

export const getCompanyOnboardingLink =
  (axios: IAxiosInstance) =>
  async (params: IGetCompanyOnboardingLinkRequestParams) => {
    try {
      const response =
        await axios.noRetry.get<GetCompanyOnboardingLinkResponse>(PATH, {
          params,
        });
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
