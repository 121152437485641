import type { ZealApiResponse } from "@zeal/common";
import type { ITriggerCompanyMicroDepositsRequestBody } from "./ITriggerCompanyMicroDepositsRequestBody.jsonschema";
import type { ITriggerCompanyMicroDepositsResponseBody } from "./ITriggerCompanyMicroDepositsResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies/microdeposits/trigger";

type TriggerCompanyMicroDepositsResponse =
  ZealApiResponse<ITriggerCompanyMicroDepositsResponseBody>;

export const triggerCompanyMicroDeposits =
  (axios: IAxiosInstance) =>
  async (body: ITriggerCompanyMicroDepositsRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<TriggerCompanyMicroDepositsResponse>(
          PATH,
          body
        );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
