import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPaycardEnrollmentActionRequiredResponse } from "./getPaycardEnrollmentActionRequired.schema";

export const getPaycardEnrollmentActionRequired = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetPaycardEnrollmentActionRequiredResponse>
    >("/api/getPaycardEnrollmentActionRequired");
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
