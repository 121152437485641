import { z } from "zod";

import { schemes } from "../schema-validation";
import { laForgeWorkLocationSchema } from "../work-locations/schemas/laForgeWorkLocation";
import { baseEventInput } from "./baseEvent";

const payloadSchema = laForgeWorkLocationSchema.pick({
  quarkWorkLocationId: true,
  quarkTaxableLocationId: true,
  street1: true,
  street2: true,
  city: true,
  state: true,
  zip: true,
  workSiteId: true,
  name: true,
});

export const companyWorkLocationEvent = baseEventInput.merge(
  z.object({
    source: z.literal("company.worklocations"),
    // this is a quark company id
    companyId: schemes.mongoObjectId(),
    payload: payloadSchema,
  })
);

export type CompanyWorkLocationEvent = z.infer<typeof companyWorkLocationEvent>;
