import type { ZealApiResponse } from "@zeal/common";
import type { ISetEmployeeOnboardStatusRequestBody } from "./ISetEmployeeOnboardStatusRequestBody.jsonschema";
import type { ISetEmployeeOnboardStatusResponseBody } from "./ISetEmployeeOnboardStatusResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/employees/setOnboardedStatusToTrue";

type SetEmployeeOnboardStatusResponse =
  ZealApiResponse<ISetEmployeeOnboardStatusResponseBody>;

export const setEmployeeOnboardStatus =
  (axios: IAxiosInstance) =>
  async (body: ISetEmployeeOnboardStatusRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<SetEmployeeOnboardStatusResponse>(PATH, body);
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
