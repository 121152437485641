import type { ZealApiResponse } from "@zeal/common";
import type { ISignCompanyAuthDocsRequestBody } from "./ISignCompanyAuthDocRequestBody.jsonschema";
import type { ISignCompanyAuthDocsResponseBody } from "./ISignCompanyAuthDocResponseBody.jsonschema";
import type { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../returnZealApiErrorResponse";

const PATH = "/companies/authorization_documents";

type SignCompanyAuthDocsResponse =
  ZealApiResponse<ISignCompanyAuthDocsResponseBody>;

export const signCompanyAuthDocs =
  (axios: IAxiosInstance) => async (body: ISignCompanyAuthDocsRequestBody) => {
    try {
      const response = await axios.noRetry.post<SignCompanyAuthDocsResponse>(
        PATH,
        body
      );
      return response.data;
    } catch (err) {
      return returnZealApiErrorResponse(err);
    }
  };
