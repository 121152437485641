import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const updateUserCard = (axios: IAxiosInstance) => {
  return async (params: { userID: string; cardID: string }) => {
    const response = await axios.noRetry.patch<IApiResponse<any>>(
      `/push-to-card/card`,
      {
        userID: params.userID,
        cardID: params.cardID,
      }
    );

    return response.data;
  };
};
