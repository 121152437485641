import { z } from "zod";

import { employeeSchema } from "../employees/schemas/baseEmployee";
import { schemes } from "../schema-validation";
import { baseEventInput } from "./baseEvent";

export const paycardsEventPayloadSchema = employeeSchema.partial();

const contractorPaycardsEvent = baseEventInput.merge(
  z.object({
    source: z.literal("contractors"),
    contractorId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
    payload: paycardsEventPayloadSchema,
  })
);

const employeePaycardsEvent = baseEventInput.merge(
  z.object({
    source: z.literal("employees"),
    employeeId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
    payload: paycardsEventPayloadSchema,
  })
);

export const paycardsEvent = z.discriminatedUnion("source", [
  contractorPaycardsEvent,
  employeePaycardsEvent,
]);

export type TContractorPaycardsEvent = z.infer<typeof contractorPaycardsEvent>;
export type TEmployeePaycardsEvent = z.infer<typeof employeePaycardsEvent>;
export type TPaycardsEvent = z.infer<typeof paycardsEvent>;
