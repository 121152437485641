import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdatePayrollRunRequestSchema,
  TUpdatePayrollRunResponseSchema,
} from "./updatePayrollRun.schema";

export const updatePayrollRun = (axios: IAxiosInstance) => {
  return async (requestBody: TUpdatePayrollRunRequestSchema) => {
    const { payrollRunID, ...rest } = requestBody;

    const response = await axios.noRetry.patch<
      IApiResponse<TUpdatePayrollRunResponseSchema>
    >(`/api/payroll-runs/${payrollRunID}`, rest);

    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
