import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TWorkerPaycardOnboardingWebhookRequest } from "../testWorkerPaycardOnboardingWebhook/testWorkerPaycardOnboardingWebhook.schema";
import { TUpdateWorkerPaycardOnboardingWebhookResponse } from "./updateWorkerPaycardOnboardingWebhook.schema";

export const updateWorkerPaycardOnboardingWebhook = (axios: IAxiosInstance) => {
  return async ({ testMode, url }: TWorkerPaycardOnboardingWebhookRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<TUpdateWorkerPaycardOnboardingWebhookResponse>
    >("/api/updateWorkerPaycardOnboardingWebhook", {
      testMode,
      url,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
