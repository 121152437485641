import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

const URL = `reports/custom-report`;

export interface IPostCustomReportRequestBody {
  readonly companyID: string;
  readonly media_type: string;
  readonly reportConfig: Record<string, any>;
}

interface IPostCustomReportResponseRequestBody
  extends IPostCustomReportRequestBody {
  readonly job_type: string;
  readonly report_format: string;
}

export interface IPostCustomReportResponseData {
  readonly created_at: string;
  readonly job_id: string;
  readonly request_body: IPostCustomReportResponseRequestBody;
  readonly status: string;
}

export interface IPostCustomReportResponseBody {
  readonly data: IPostCustomReportResponseData;
  readonly status: number;
  readonly success: boolean;
}

export const postCustomReport =
  (axios: IAxiosInstance) =>
  async (requestBody: IPostCustomReportRequestBody) => {
    const response = await axios.noRetry.post(URL, requestBody);
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error(
      "Error parsing CSV file. Please ensure file matches provided template."
    );
  };
