import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPayrollRunsRequestSchema,
  TGetPayrollRunsResponseSchema,
} from "./getPayrollRuns.schema";

export interface IGetPayrollRunsParams {
  readonly companyID?: string;
}

export const getPayrollRuns = (axios: IAxiosInstance) => {
  return async (params: TGetPayrollRunsRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetPayrollRunsResponseSchema>
    >(`/api/payroll-runs`, {
      params,
    });

    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
