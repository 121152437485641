import { z } from "zod";

import { employeeSchema } from "../employees/schemas/baseEmployee";
import { EPaycardOnboardingStatus } from "../pay-cards";
import { schemes } from "../schema-validation";
import { baseEventInput } from "./baseEvent";

export const paycardOnboardingStatusEventPayloadSchema =
  employeeSchema.partial();

const contractorPaycardOnboardingStatusEvent = baseEventInput.merge(
  z.object({
    source: z.literal("contractors"),
    contractorId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
    payload: z.object({
      newPaycardOnboardingStatus: z.nativeEnum(EPaycardOnboardingStatus),
    }),
  })
);

const employeePaycardOnboardingStatusEvent = baseEventInput.merge(
  z.object({
    source: z.literal("employees"),
    employeeId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
    payload: z.object({
      newPaycardOnboardingStatus: z.nativeEnum(EPaycardOnboardingStatus),
    }),
  })
);

export const paycardOnboardingStatusEvent = z.discriminatedUnion("source", [
  contractorPaycardOnboardingStatusEvent,
  employeePaycardOnboardingStatusEvent,
]);

export type TContractorPaycardOnboardingStatusEvent = z.infer<
  typeof contractorPaycardOnboardingStatusEvent
>;
export type TEmployeePaycardOnboardingStatusEvent = z.infer<
  typeof employeePaycardOnboardingStatusEvent
>;
export type TPaycardOnboardingStatusEvent = z.infer<
  typeof paycardOnboardingStatusEvent
>;
