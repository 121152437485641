import { z } from "zod";

export const cardPin = () =>
  z
    .string()
    .min(4, {
      message: "Pin must be 4 digits long",
    })
    .max(4, {
      message: "Pin must be 4 digits long",
    });
