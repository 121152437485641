import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import { createCompany } from "./createCompany/createCompany";
import { createCompanyBankAccount } from "./createCompanyBankAccount/createCompanyBankAccount";
import { genEmployerLoginLink } from "./genEmployerLoginLink/genEmployerLoginLink";
import { getCompanies } from "./getCompanies/getCompanies";
import { getCompanyAuthDocs } from "./getCompanyAuthDocs/getCompanyAuthDocs";
import { getCompanyOnboardingLink } from "./getCompanyOnboardingLink/getCompanyOnboardingLink";
import { setCompanyOnboardStatus } from "./setCompanyOnboardStatus/setCompanyOnboardStatus";
import { signCompanyAuthDocs } from "./signCompanyAuthDoc/signCompanyAuthDocs";
import { triggerCompanyMicroDeposits } from "./triggerCompanyMicroDeposits/triggerCompanyMicroDeposits";
import { verifyCompanyMicroDeposits } from "./verifyCompanyMicroDeposits/verifyCompanyMicroDeposits";

export function CompanyApi(instance: IAxiosInstance) {
  return {
    Create: createCompany(instance),
    CreateBankAccount: createCompanyBankAccount(instance),
    GetAll: getCompanies(instance),
    GetAuthDocs: getCompanyAuthDocs(instance),
    GenLoginLink: genEmployerLoginLink(instance),
    GetOnboardingLink: getCompanyOnboardingLink(instance),
    SetOnboardedStatusToTrue: setCompanyOnboardStatus(instance),
    SignAuthDoc: signCompanyAuthDocs(instance),
    TriggerMicroDeposits: triggerCompanyMicroDeposits(instance),
    VerifyMicroDeposits: verifyCompanyMicroDeposits(instance),
  };
}
