import { paycardTransaction, schemes, zSchema } from "@zeal/common";

export const getPayCardTransactionRequestSchema = zSchema.object({
  paycard_id: schemes.uuid(),
  transaction_id: schemes.uuid(),
});

export type TGetPayCardTransactionRequestSchema = zSchema.infer<
  typeof getPayCardTransactionRequestSchema
>;

export const getPayCardTransactionResponseSchema = paycardTransaction;

export type TGetPayCardTransactionResponseSchema = zSchema.infer<
  typeof getPayCardTransactionResponseSchema
>;
