import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetWalletTransactionsResponseSchema,
  TGetWalletTransactionssRequestSchema,
} from "./getWalletTransactions.schema";

export const getWalletTransactions = (axios: IAxiosInstance) => {
  return async (params: TGetWalletTransactionssRequestSchema) => {
    const { wallet_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetWalletTransactionsResponseSchema>
    >(`/api/wallets/${wallet_id}/transactions`, { params: rest });

    return response.data;
  };
};
