import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreatePayrollRunChecksRequestSchema,
  TCreatePayrollRunChecksResponseSchema,
} from "./createPayrollRunChecks.schema";

export const createPayrollRunChecks = (axios: IAxiosInstance) => {
  return async (requestBody: TCreatePayrollRunChecksRequestSchema) => {
    const { payroll_run_id, ...rest } = requestBody;

    const response = await axios.noRetry.post<
      IApiResponse<TCreatePayrollRunChecksResponseSchema>
    >(`/api/payroll-runs/${payroll_run_id}/checks`, rest);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
