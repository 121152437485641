import { paycardTransaction, schemes, zSchema } from "@zeal/common";

export const getPayCardTransactionsRequestSchema = zSchema.object({
  limit: zSchema.number().optional(),
  pay_card_id: schemes.uuid(),
  start_at: schemes.uuid().optional(),
});

export type TGetPayCardTransactionsRequestSchema = zSchema.infer<
  typeof getPayCardTransactionsRequestSchema
>;

export const getPayCardTransactionsResponseSchema =
  zSchema.array(paycardTransaction);

export type TGetPayCardTransactionsResponseSchema = zSchema.infer<
  typeof getPayCardTransactionsResponseSchema
>;
