import {
  IACHDebitSubmissionRequest,
  ICreateFileSubmissionRequest,
  ICreateJobFileRequest,
  IFileSubmission,
  IGeneratedFiles,
  IGeneratedFilesPending,
  IGetJobStatusRequest,
  IGetOustandingDeadlinesSummaryRequest,
  IGetOustandingDeadlinesSummaryResponse,
  IGetOustandingPayments,
  IGetPaymentDeadlinesForCompanyIDRequest,
  IGetTXPDataFromDeadlinesRequest,
  IPaymentSubmission,
  IPostTXPPayment,
  IResponseBase,
  ITXPMetaResponse,
  TApiResponse,
} from "@zeal/common";
import { IAmountPaidSummary } from "@zeal/common/dist/bolarus/IGetOustandingPayments";
import { IUpdateDeadlineStatus } from "@zeal/common/dist/bolarus/deadline_types";
import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../AbstractApiClient/IApiResponse";
import { createFileJob } from "./routes/create_file_job";
import { createFileSubmission } from "./routes/create_file_submission";
import { getDeadlines } from "./routes/get-deadlines";
import { getSummary } from "./routes/get-summary";
import { getTXPMeta } from "./routes/get-txp-meta";
import { checkJobStatus } from "./routes/get_job_status";
import { postTXPPayment } from "./routes/post-txp-payment";
import { postACHDebitPayment } from "./routes/post_ach_debit_payment";
import { updatePaymentSubmission } from "./routes/update-payment-submission";
import { updateDeadline } from "./routes/update_deadlines";

export interface ICompassTuvokClient {
  getDeadlineSummary(
    body: IGetOustandingDeadlinesSummaryRequest
  ): Promise<IResponseBase<IGetOustandingDeadlinesSummaryResponse[]>>;
  getDeadlines(
    body: IGetPaymentDeadlinesForCompanyIDRequest
  ): Promise<IResponseBase<IGetOustandingPayments[]>>;
  getTXPMeta(
    body: IGetTXPDataFromDeadlinesRequest
  ): Promise<IResponseBase<ITXPMetaResponse[]>>;
  postTXPPayment(
    body: IPostTXPPayment[]
  ): Promise<TApiResponse<IPaymentSubmission[], "payments">>;
  checkJobStatus(
    body: IGetJobStatusRequest
  ): Promise<IApiResponse<IGeneratedFiles>>;
  createFileJob(
    body: ICreateJobFileRequest
  ): Promise<IResponseBase<IGeneratedFilesPending>>;
  createFileSubmission(
    body: ICreateFileSubmissionRequest
  ): Promise<IResponseBase<IFileSubmission>>;
  postACHDebitPayment(
    body: IACHDebitSubmissionRequest[]
  ): Promise<IResponseBase<IPaymentSubmission[]>>;
  updateDeadline(body: IUpdateDeadlineStatus): Promise<any>;
  updatePaymentSubmission(body: IAmountPaidSummary[]): Promise<any>;
}

export class CompassTuvokClient
  extends AbstractApiClient
  implements ICompassTuvokClient
{
  public readonly getDeadlineSummary = getSummary(this.Instance);
  public readonly getDeadlines = getDeadlines(this.Instance);
  public readonly getTXPMeta = getTXPMeta(this.Instance);
  public readonly postTXPPayment = postTXPPayment(this.Instance);
  public readonly checkJobStatus = checkJobStatus(this.Instance);
  public readonly createFileJob = createFileJob(this.Instance);
  public readonly createFileSubmission = createFileSubmission(this.Instance);
  public readonly postACHDebitPayment = postACHDebitPayment(this.Instance);
  public readonly updateDeadline = updateDeadline(this.Instance);
  public readonly updatePaymentSubmission = updatePaymentSubmission(
    this.Instance
  );
}
