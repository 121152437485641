type AppContext = { readonly logger: any }; // @TODO(ZF1-6944): move AppContext type into common

export const EJitterStrategy = {
  EQUAL: "equal",
  FULL: "full",
  NONE: "none",
} as const;

export type EJitterStrategy =
  (typeof EJitterStrategy)[keyof typeof EJitterStrategy];

const jitter = (
  value: number,
  strategy: EJitterStrategy = EJitterStrategy.NONE
) => {
  switch (strategy) {
    case EJitterStrategy.EQUAL:
      return value / 2 + randomIntBetween(0, value / 2);
    case EJitterStrategy.FULL:
      return randomIntBetween(0, value);
    default:
      return value;
  }
};

type Fn = (...args: any[]) => Promise<any> | any;

type ErrorCallback = (error?: unknown) => void | typeof ABORT_RETRY;

export const ABORT_RETRY = Symbol("abort");

const randomIntBetween = (min: number, max: number) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
};

interface ExpBackoffRetryOptions<E extends ErrorCallback> {
  readonly onError?: E;
  readonly baseDelayMs?: number;
  readonly jitterStrategy?: EJitterStrategy;
  readonly maxAttempts?: number;
}

export const exponentialBackoffRetry = async <
  F extends Fn,
  E extends ErrorCallback,
>(
  context: AppContext,
  fn: F,
  {
    onError,
    maxAttempts = 5,
    baseDelayMs = 1000,
    jitterStrategy = EJitterStrategy.NONE,
  }: ExpBackoffRetryOptions<E> = {}
) => {
  let attempt = 1;
  // use iteration over recursion to avoid a promise recursion memory leak
  while (attempt <= maxAttempts) {
    const metadata = { attempt, maxAttempts, baseDelayMs, jitterStrategy };
    try {
      await fn();
      context.logger.info({ message: `retry/success`, metadata });
      return;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";

      const result = onError?.(error);
      if (result === ABORT_RETRY) {
        context.logger.error({
          message: "retry/aborted",
          error: errorMessage,
          metadata,
        });
        return;
      }
      context.logger.error({
        message: "retry/error",
        error: errorMessage,
        metadata,
      });

      // apply jitter to reduce competing clients
      const delayMs = jitter(baseDelayMs * 2 ** attempt, jitterStrategy);
      await new Promise((resolve) => {
        setTimeout(resolve, delayMs);
      });
      context.logger.info({
        message: `retry/attempt`,
        metadata: {
          delayMs,
          ...metadata,
        },
      });

      attempt += 1;
    }
  }

  context.logger.info({
    message: `retry/failed/maxAttempts`,
    metadata: {
      maxAttempts,
      baseDelayMs,
      jitterStrategy,
    },
  });
};
