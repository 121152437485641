import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TPostKickBackWorkerI9Request } from "./postKickBackWorkerI9RequestSchema";

export const postKickBackWorkerI9 = (axios: IAxiosInstance) => {
  return async (data: TPostKickBackWorkerI9Request) => {
    const response = await axios.noRetry.post<ZealApiResponse<boolean>>(
      "/api/postKickBackWorkerI9",
      { ...data }
    );
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
