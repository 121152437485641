import {
  Attribute,
  CUSTOM_ATTRIBUTE_ROUTES,
  ELaForgeWorkerEnum,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const queryWorkerAttributes = (axios: IAxiosInstance) => {
  return async (data: {
    companyID: string;
    workerType: ELaForgeWorkerEnum;
    workerID?: string;
    responseSetBy?: "worker" | "admin";
  }) => {
    const response = await axios.noRetry.post<
      IApiResponse<{ attributes: Attribute[] }>
    >(CUSTOM_ATTRIBUTE_ROUTES.QUERY_WORKER_ATTRIBUTES, data);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
