import { z } from "zod";
import {
  EWorkerInformationSection,
  schemes,
  UserAccountType,
} from "@zeal/common";

export const postGenerateShareableLinkSchema = z.object({
  companyID: schemes.companyID(),
  formTemplateIDs: z.array(z.string()).optional(),
  section: z.nativeEnum(EWorkerInformationSection),
  send_to_worker: z.boolean().optional(),
  workerID: schemes.mongoObjectId(),
  workerType: z.union([
    z.literal(UserAccountType.CompanyContractor),
    z.literal(UserAccountType.CompanyWorker),
  ]),
});

export type TPostGenerateShareableLinkRequest = z.infer<
  typeof postGenerateShareableLinkSchema
>;
