import { z } from "zod";
import { RouteMap } from "./customerReporting";

// Add after line 61, before the end of file:

export const queryBuilderRequestSchema = z.object({
  companyID: z.string(),
  projections: z.record(z.record(z.any())),
  sort: z.record(z.string(), z.literal(1).or(z.literal(-1))).optional(),
  filters: z.record(z.any()),
  page: z.number(),
  pageSize: z.number(),
  responseType: z.literal("json"),
});

export type QueryBuilderRequest = z.infer<typeof queryBuilderRequestSchema>;

export type QueryBuilderResponse = {
  data: any[];
  pagination: {
    page: number;
    pageSize: number;
    totalRecords: number;
    totalPages: number;
  };
};

export type QueryBuilderResponseShape = {
  kind: RouteMap.custom_report;
  data: any[];
  pagination: {
    page: number;
    pageSize: number;
    totalRecords: number;
    totalPages: number;
  };
};
